import React, { useEffect, useState, useMemo } from "react";
import Form from "react-bootstrap/Form";
import he from "he";
import { Input } from "rsuite"; // Import the HTML entity decoder library
import { produce } from "immer";




export default function FormulaBuilderPreprationInstructionsSection({
  formulaBuilderId,
  data,
  ingrediuentsTags = [],
  ingredients,
  onChange,
  formulaDeatils,
  display_name,
  thisFormData,
}) {

  const [selectedTag, setSelectedTag] = useState([]);
  const [formValue, setFormValue] = React.useState({});

  var requirements = [
    {
      column: "preparation_instructions",
      name: "Preparation Instructions",
      is_required: 0,
      key: "preparation_instructions",
      allow_zero: false,
    },
  ];

  const finalTagArr = (Array.isArray(ingredients) ? ingredients : [])
  .map((v) => v?.["formula_dosage_column_herb_add"])
  .filter(Boolean); // Removes empty values

  const filteredArray = [...new Set(finalTagArr)]; // Ensures unique values

  
  useEffect(() => {
    if (
      formulaDeatils &&
      formulaDeatils.id 
    ) {
      var preparation_instructions = formulaDeatils.preparation_instructions
        ? JSON.parse(formulaDeatils.preparation_instructions)
        : [];
      //var addedTags = []; 
      if(preparation_instructions.length > 0){
        //console.log('preparation_instructions', preparation_instructions);
        preparation_instructions.map((value, key) => {
          const newTag = {
            tag_id: value?.tag_id,
            tag_selected: value ? value.tag_selected : "",
            tag_selected_name: value ? value.tag_selected_name : "",
            variableText: value ? value.variableText : "",
            variableArr: value ? value.variableArr : [],
            text: decodeEntities(value?.text || ""),
          };
          //console.log('saved-newTag', value?.tag_id,value?.text);
          setSelectedTag((prev) =>
            produce(prev, (draft) => {
              const index = draft.findIndex((val) => val.tag_id == value.tag_id);
              if (index != -1) {
                draft[index] = newTag;
              } else {
                draft.push(newTag);
              }
            })
          );
        })
      }
    }
  }, [formulaBuilderId,formulaDeatils]);

  const HandelTags = (tag_id, value, array) => {
    if (!array) return;
    //console.log('HandelTags', { tag_id, value, array });
    const selectedRow = array.tags_data.find((obj) => obj.id == value);

    const newTag = {
      tag_id,
      tag_selected: value,
      tag_selected_name: selectedRow ? selectedRow.title : "",
      variableText: selectedRow ? selectedRow.text : "",
      variableArr: [],
      text: selectedRow ? selectedRow.text : "",
    };

    if (selectedRow) {
      const matches = selectedRow.text.match(/\[(.*?)\]/g) || [];
      newTag.variableArr = matches.map((v) => {
        const variableName = v.replace(/\[|\]/g, "").trim();
        return {
          field: variableName.toLowerCase().replace(/\s+/g, "_"),
          name: variableName,
          replace: v.trim(),
          value: "",
        };
      });
    }

    setSelectedTag((prev) =>
      produce(prev, (draft) => {
        const index = draft.findIndex((val) => val.tag_id == tag_id);
        if (index !== -1) {
          draft[index] = newTag;
        } else {
          draft.push(newTag);
        }
      })
    );
  }
  
  
  const HandelTagVariables = (tag_id, value, field, selectedRow = {}) => {
    if (!selectedRow || !selectedRow.variableArr) return;
  
    let finalText = selectedRow.variableText || "";
    
    // Use map() to create a new array instead of mutating the original
    const updatedVariableArr = selectedRow.variableArr.map((v) => {
      if (v.field === field) {
        return { ...v, value }; // Update only the matching field
      }
      return v;
    });
  
    // Replace text only if value is present
    updatedVariableArr.forEach((v) => {
      if (v.value) {
        finalText = finalText.replace(v.replace, v.value);
      }
    });
  
    // Create a new updated object
    const updatedRow = {
      ...selectedRow,
      variableArr: updatedVariableArr,
      text: finalText,
    };
  
    // Update the state immutably using immer
    setSelectedTag((prev) =>
      produce(prev, (draft) => {
        const index = draft.findIndex((val) => val.tag_id === tag_id);
        if (index !== -1) {
          draft[index] = updatedRow;
        }
      })
    );
  };
  

  const HandelPreviewText = (tag_id, selectedRow = [], e) => {
    const newTag = {
      tag_id,
      tag_selected: selectedRow ? selectedRow.tag_selected : "",
      tag_selected_name: selectedRow ? selectedRow.tag_selected_name : "",
      variableText: selectedRow ? selectedRow.variableText : "",
      variableArr: selectedRow ? selectedRow.variableArr : [],
      text: e.target.value,
    };

    setSelectedTag((prev) =>
      produce(prev, (draft) => {
        const index = draft.findIndex((val) => val.tag_id == tag_id);
        if (index !== -1) {
          draft[index] = newTag;
        } else {
          draft.push(newTag);
        }
      })
    );
  }

  const decodeEntities = (html) => {
    if (!html) return ""; // Return an empty string if input is undefined or null
    const doc = new DOMParser().parseFromString(he.decode(html), "text/html");
    return doc.documentElement.textContent;
  };
  
  useEffect(() => {
    onChange(selectedTag);
    if (selectedTag.length != 0) {
      formValidation();
    }
  }, [selectedTag]);

  const formValidation = () => {
    formValue["preparation_instructions"] = selectedTag;
    var Final_array = [];
    Object.values(requirements).forEach((field) => {
      const ikey = field.key;
      const temp = {
        ...field,
        error: "",
        value: formValue[ikey],
      };
      Final_array.push(temp);
    });

    thisFormData(Final_array, "FORMULAS_PREPARATION_INSTRUCTIONS");
  };


  if (ingrediuentsTags.length === 0) {
    return null;
  }

  return (
    <>
      <div className="FormulaBuilderPreprationInstructionsSection">
        <h2 className="title">
          {!data.blank_preparation_instructions && display_name}
        </h2>
        {/* <pre>{JSON.stringify(filteredArray, null, 2)}</pre> */}
        <div className="row">
          <div className="col-8">
            {ingrediuentsTags.map((value, key) => {
              if(filteredArray.includes(value.id) == false) return null;
              var tags_data = value && value.tags_data != '' ? value.tags_data : [];
              const thisSelectedTag = selectedTag.find((obj) => obj.tag_id == value.id) || {};
              return (
                <div key={key}>
                  <h5 className="instructionsTitle mt-4">
                    {value?.name} Instructions
                  </h5>
                  <br></br>
                  <div
                    className=""
                    style={{
                      padding: "0px",
                      display: 'flex', flexWrap: 'wrap'
                    }}
                  >
                    <Form.Group
                      className="col-3"
                      style={{ paddingLeft: "0px" }}
                    >
                      <Form.Select
                        style={{
                          padding: ".3rem 2.25rem .3rem .75rem",
                          border: "1px solid black",
                        }}
                        onChange={(e) => {
                          HandelTags(value.id, e.target.value, value)
                        }}
                      >
                        <option value="" placeholder="Tag Insert">
                          Select..
                        </option>
                        {tags_data.map((val, k) => {
                          return (
                            <option
                              //key={val?.id}
                              //data-text={val?.text}
                              value={val?.id}
                              selected={thisSelectedTag?.tag_selected == val?.id}
                            >
                              {val?.title}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                    {/* selected tag variables */}
                    <div className='col-9 row' style={{ marginLeft: "0.1rem" }}>
                    {thisSelectedTag?.variableArr?.map((v, k) => {
                      return (
                        <div
                          key={k}
                          className="col-2"
                          title={v.name}
                        >
                          <input
                            type="text"
                            value={v.value}
                            placeholder={v.name}
                            title={v.name}
                            onChange={(e) =>
                              HandelTagVariables(
                                value.id,
                                e.target.value,
                                v.field,
                                thisSelectedTag
                              )
                            }
                            className="form-control text-center"
                          />
                        </div>
                      );
                    })}
                    </div>
                    {/* preview textarea */}
                    <textarea
                      as="textarea"
                      rows={6}
                      value={decodeEntities(thisSelectedTag?.text || "")} // Ensure default empty string
                      className="col-12"
                      onChange={(e) => {
                        HandelPreviewText(value.id, thisSelectedTag, e);
                      }}
                      style={{
                        marginTop: "0.8rem",
                        border: "1px solid black",
                        width: "83%",
                      }}
                    />
                    {/* end textarea */}
                    {/* <pre>{JSON.stringify(thisSelectedTag, null, 2)}</pre> */}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  );
}
