import React, { useEffect, useState } from 'react'
import FormulaSectionTextBox from '../helpers/TextBox';
const FormulaBuilderSummarySection = ({
  currency,
  formulaSummary,
  display_name,
  allowEnterQty,
  allowRawQty,
  ingredients,
  formulaTextBox,
  formulaLabelSetting
}) => {
  const decimalPlaceMap = {};
  ingredients.forEach((ingredient) => {
    decimalPlaceMap[ingredient.formula_dosage_column_form] = ingredient.final_decimal_place;
  });
  const tableData = formulaLabelSetting?.tabelSettings
  return (
    <>
      <div className="FormulaBuilderSummarySection">
        {formulaSummary && Object.keys(formulaSummary).length > 0 && (
          <>
            <h2 className="title mt-5">{display_name}</h2>
            <FormulaSectionTextBox
              data={formulaTextBox['FORMULA_SUMMARY_BELOW']}
              dosageFormulaKey=''
            />
            <br />
          </>
        )}

        <div className="col-6" >
          {formulaSummary &&
            Object.keys(formulaSummary).map((ingredientType, measurement) => {
              const this_decimal = decimalPlaceMap[ingredientType];
              return (
                <table
                  className="table TableMainadd table-bordered"
                  style={{ overflowX: "unset", width: '85%', ...formulaLabelSetting.tabelMarginSettings }}
                >
                  <thead>
                    <tr>
                      <th className="col-6" style={{ ...formulaLabelSetting.tableHeaderSetting }}>
                        {ingredientType}
                      </th>
                      {Object.keys(formulaSummary[ingredientType]).map(
                        (measurement) => (
                          <th className="col-4 col-form-label" style={{ width: formulaLabelSetting.width, ...formulaLabelSetting.tableHeaderSetting }} key={measurement}>
                            {measurement}
                          </th>
                        )
                      )}
                    </tr>
                  </thead>
                  <tbody
                    className={`tbody ${ingredientType}`}
                    key={ingredientType}
                  >
                    {formulaSummary[ingredientType].quantitySelected !== 0 && (
                      <>
                        {/* Ingredients Quantity Row */}
                        <tr>
                          <td className="column col-form-label" style={{ ...tableData }}>Number of Ingredients</td>
                          {Object.keys(formulaSummary[ingredientType]).map(
                            (measurement) =>
                              measurement !== "quantitySelected" && (
                                <React.Fragment key={measurement}>
                                  <td style={{ ...tableData }}>
                                    {
                                      formulaSummary[ingredientType][
                                        measurement
                                      ].quantitySelected
                                    }
                                  </td>
                                </React.Fragment>
                              )
                          )}
                        </tr>
                        {/*Enter Qty*/}
                        {allowEnterQty && (
                          <tr>
                            {Object.keys(formulaSummary[ingredientType]).map(
                              (measurement, index) =>
                                measurement !== "quantitySelected" && (
                                  <React.Fragment key={measurement}>
                                    {index %
                                      Object.keys(
                                        formulaSummary[ingredientType]
                                      ).length ===
                                      0 && (
                                        <td className="column col-form-label" style={{ ...tableData }}>
                                          Total{" "}
                                          {
                                            formulaSummary[ingredientType][
                                              measurement
                                            ].qtyValueColumnName
                                          }{" "}
                                          Entered
                                        </td>
                                      )}
                                    <td style={{ ...tableData }}>
                                      {formulaSummary[ingredientType][
                                        measurement
                                      ].totalQty.toFixed(this_decimal)}
                                    </td>
                                  </React.Fragment>
                                )
                            )}
                          </tr>
                        )}

                        {/*Raw Qty*/}
                        {allowRawQty && (
                          <tr>
                            {Object.keys(formulaSummary[ingredientType]).map(
                              (measurement, index) =>
                                measurement !== "quantitySelected" && (
                                  <React.Fragment key={measurement}>
                                    {index %
                                      Object.keys(
                                        formulaSummary[ingredientType]
                                      ).length ===
                                      0 && (
                                        <td className="column col-form-label" style={{ ...tableData }}>
                                          Total{" "}
                                          {
                                            formulaSummary[ingredientType][
                                              measurement
                                            ].rawPerDayColumnName
                                          }{" "}
                                          Entered
                                        </td>
                                      )}
                                    <td style={{ ...tableData }}>
                                      {formulaSummary[ingredientType][
                                        measurement
                                      ].rawPerDay.toFixed(this_decimal)}
                                    </td>
                                  </React.Fragment>
                                )
                            )}
                          </tr>
                        )}
                        <tr>
                          {Object.keys(formulaSummary[ingredientType]).map(
                            (measurement, index) =>
                              measurement !== "quantitySelected" && (
                                <React.Fragment key={measurement}>
                                  {index %
                                    Object.keys(formulaSummary[ingredientType])
                                      .length ===
                                    0 && (
                                      <td className="col-form-label"
                                        key={measurement}
                                        style={{ textTransform: "capitalize", ...tableData }}
                                      >
                                        {"Total Amount Received"}
                                      </td>
                                    )}
                                  <td style={{ ...tableData }}>
                                    {formulaSummary[ingredientType][
                                      measurement
                                    ].totalQtyReceived.toFixed(
                                      this_decimal
                                    )}{" "}
                                    {measurement}
                                  </td>
                                </React.Fragment>
                              )
                          )}
                        </tr>
                        <tr>
                          <td className="column col-form-label" style={{ ...tableData }}>Subtotal</td>
                          {Object.keys(formulaSummary[ingredientType]).map(
                            (measurement) =>
                              measurement !== "quantitySelected" && (
                                <td key={measurement} style={{ ...tableData }}>
                                  {currency}
                                  {formulaSummary[ingredientType][
                                    measurement
                                  ].subtotal.toFixed(2)}
                                </td>
                              )
                          )}
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              );
            })}
        </div>
        {/* <div style={{ marginBottom: "30px" }}></div> */}
      </div>
    </>
  );
}

export default FormulaBuilderSummarySection
