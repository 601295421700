import axios from 'axios'
import { APIConfig, APIHeaders } from '../../apiConfig'
import React from "react";

//const { props.APIURL, props.DISPENSARY_TOKEN, PRAC_ID } = APIConfig

const cancelTokens = new Map();

const createCancelToken = (key) => {
  if (cancelTokens.has(key)) {
    const cancelToken = cancelTokens.get(key);
    cancelToken.cancel('Operation canceled due to new request.');
  }
  const source = axios.CancelToken.source();
  cancelTokens.set(key, source);
  return source.token;
};


// get formula builder data
export const ApiFormulaBuilderDetails = async (props) => {
  //console.log('ApiFormulaBuilderDetails START', props.APIURL, props.DISPENSARY_TOKEN, props)
  let response
  const userData = {
    response_type: 'all',
    dispensary_token: props.DISPENSARY_TOKEN,
    practitioner_id: props.pracId,
    ingredient_id: props.formulaBuilderId,
  }
  // console.log('ApiFormulaBuilderDetails send data', userData, 'userData')
  try {
    response = await axios.post(props.APIURL, userData, { headers: APIHeaders })
  } catch (e) {
    // catch error
    throw new Error(e.message)
  }
  // return JSON.parse(response?.data.results.general_section) // or set initial value
  //console.log(response.data)
  return response?.data.results // or set initial value
}

// get Formula Builder -> Dosage Inputs
export const ApiFormulaBuilderDosagesDetails = async (props) => {
  let response

  const getDosageInputsResponseObject = {
    response_type: 'getDosageInputs',
    dispensary_token: props.DISPENSARY_TOKEN,
    practitioner_id: props.pracId,
    ingredient_id: props.formulaBuilderId,
    // formula_t: Formuladata || DosageData
    formula_t: props.formulaDosagesType || '',
    ingredient_selection_type: props.ingredient_selection_type,
  }
  //console.log('dosagesApiData', getDosageInputsResponseObject)
  try {
    response = await axios.post(props.APIURL, getDosageInputsResponseObject, {
      headers: APIHeaders,
    })
  } catch (e) {
    // catch error
    throw new Error(e.message)
  }
  return response?.data.results // or set initial value
}

export const ApiFormulaBulderIngredientDetails = async (props) => {
  let response

  const getDosageInputsResponseObject = {
    response_type: 'getIngredientDetailsUsingId',
    dispensary_token: props.DISPENSARY_TOKEN,
    practitioner_id: props.pracId,
    herb_id: props.Herb_Id,
    ingredient_id: props.formulaBuilderId,
  }

  try {
    response = await axios.post(props.APIURL, getDosageInputsResponseObject, {
      headers: APIHeaders,
    })
  } catch (e) {
    throw new Error(e.message)
  }
  return response?.data.results // or set initial value
}

export const ApiFormulaBuilderList = async (props) => {
  //console.log('ApiFormulaBuilderDetails START', props.APIURL, props.DISPENSARY_TOKEN, props)
  let response

  const userData = {
    response_type: 'getFormulaBuilderList',
    dispensary_token: props.DISPENSARY_TOKEN,
    practitioner_id: props.pracId,
  }
  // console.log('ApiFormulaBuilderDetails send data', userData, 'userData')
  try {
    response = await axios.post(props.APIURL, userData, {
      headers: APIHeaders,
    })
  } catch (e) {
    // catch error
    throw new Error(e.message)
  }
  // return JSON.parse(response?.data.results.general_section) // or set initial value
  //console.log(response.data)
  return response?.data.results // or set initial value
}
export const ApiVerifyPrac = async (props) => {
  //console.log('ApiFormulaBuilderDetails START', props.APIURL, props.DISPENSARY_TOKEN, props)
  let response

  const userData = {
    response_type: 'verifyPrac',
    dispensary_token: props.DISPENSARY_TOKEN,
    practitioner_id: props.pracId,
  }
  // console.log('ApiFormulaBuilderDetails send data', userData, 'userData')
  try {
    response = await axios.post(props.APIURL, userData, { headers: APIHeaders })
  } catch (e) {
    // catch error
    throw new Error(e.message)
  }
  // return JSON.parse(response?.data.results.general_section) // or set initial value
  //console.log(response.data)
  return response?.data.results // or set initial value
}

export const ApiFormulaBulderIngredientLists = async (props) => {
  let response
  //const cancelToken = createCancelToken('ApiFormulaBulderIngredientLists');
  const getDosageInputsResponseObject = {
    response_type: 'getFormulaIngredientsList',
    dispensary_token: props.DISPENSARY_TOKEN,
    practitioner_id: props.pracId,
    //herb_id: props.Herb_Id,
    ingredient_id: props.formulaBuilderId,
    ingredient_selection_type: props.ingredient_selection_type,
    allowed_ingredients: props.allowed_ingredients,
  }

  try {
    response = await axios.post(props.APIURL, getDosageInputsResponseObject, {
      headers: APIHeaders,
      //cancelToken
    })
  } catch (e) {
    // if (axios.isCancel(e)) {
    //   console.log('Request canceled', e.message);
    // } else {
    //   throw new Error(e.message);
    // }
    throw new Error(e.message)
  }
  return response ? response.data.results : [] // or set initial value
}

export const ApiFormulaDetails = async (props) => {
  let response

  const getDosageInputsResponseObject = {
    response_type: 'getFormulaDetails',
    dispensary_token: props.DISPENSARY_TOKEN,
    practitioner_id: props.pracId,
    herb_id: props.formulaId,
    ingredient_id: props.formulaBuilderId,
    is_order: props.is_order,
  }

  try {
    response = await axios.post(props.APIURL, getDosageInputsResponseObject, {
      headers: APIHeaders,
    })
  } catch (e) {
    throw new Error(e.message)
  }
  return response?.data.results || null
}
export const ApiSaveFormulaDetails = async (props) => {
  let response
 // Sanitize formData by removing React-specific elements
 //const sanitizedFormData = await sanitizeFormData(props.formData);

  const getDosageInputsResponseObject = {
    response_type: 'saveFormulaDetails',
    dispensary_token: props.DISPENSARY_TOKEN,
    practitioner_id: props.pracId,
    herb_id: props.formulaId,
    ingredient_id: props.formulaBuilderId,
    form: props.formData,
    //form: sanitizedFormData,
    is_order: props.is_order,
  }
 
  try {
    response = await axios.post(props.APIURL, getDosageInputsResponseObject, {
      headers: APIHeaders,
    })
  } catch (e) {
    throw new Error(e.message)
  }
  return response?.data.results || null
}
export const ApiFormulaIngredientTabData = async (props) => {
  let response

  const getDosageInputsResponseObject = {
    response_type: 'ingredientTabData',
    dispensary_token: props.DISPENSARY_TOKEN,
    practitioner_id: props.pracId,
    formula_t: props.formulaDosagesType,
    ingredient_id: props.formulaBuilderId,
    patient_id: props.patient_id,
  }

  try {
    response = await axios.post(props.APIURL, getDosageInputsResponseObject, {
      headers: APIHeaders,
    })
  } catch (e) {
    throw new Error(e.message)
  }
  return response?.data.results || null
}

export const getThisFormulaIngredients = async (props) => {
  let response

  const getDosageInputsResponseObject = {
    response_type: 'ingredientTabData',
    dispensary_token: props.DISPENSARY_TOKEN,
    practitioner_id: props.pracId,
    ingredient_id: props.thisFormula,
  }

  try {
    response = await axios.post(props.APIURL, getDosageInputsResponseObject, {
      headers: APIHeaders,
    })
  } catch (e) {
    throw new Error(e.message)
  }
  return response?.data.results || null
}

export const getSearchIngredients = async (props) => {
  let response

  const getDosageInputsResponseObject = {
    response_type: 'searchIngredients',
    dispensary_token: props.DISPENSARY_TOKEN,
    practitioner_id: props.pracId,
    formula_builder_id: props.formula_builder_id,
    ingredient_selection_type: props.ingredient_selection_type,
    allowed_ingredients: props.allowed_ingredients,
    //excludes_ingredients: props.excludes_ingredients,
    category: props.selectedCategory,
    channel: props.selectedChannel,
    flavour: props.selectedFlavor,
    symptom: props.selectedSymptoms,
    action: props.selectedActions,
    condition: props.selectedConditions,
    attributes: props.selectedAttributes,
    search: props.search,
  }

  try {
    response = await axios.post(props.APIURL, getDosageInputsResponseObject, {
      headers: APIHeaders,
    })
  } catch (e) {
    throw new Error(e.message)
  }
  return response?.data.results || []
}

export const getIngredientViewDeatils = async (props) => {
  let response

  const getDosageInputsResponseObject = {
    response_type: 'ingredientView',
    dispensary_token: props.DISPENSARY_TOKEN,
    practitioner_id: props.pracId,
    ingredient_id: props.ingredient_id,
  }

  try {
    response = await axios.post(props.APIURL, getDosageInputsResponseObject, {
      headers: APIHeaders,
    })
  } catch (e) {
    throw new Error(e.message)
  }
  return response?.data.results || null
}

export const ApiFormulaExtraData = async (props) => {
  let response

  const getDosageInputsResponseObject = {
    response_type: 'formula_extra_data',
    dispensary_token: props.DISPENSARY_TOKEN,
    practitioner_id: props.pracId,
  }

  try {
    response = await axios.post(props.APIURL, getDosageInputsResponseObject, {
      headers: APIHeaders,
    })
  } catch (e) {
    throw new Error(e.message)
  }
  return response?.data.results || []
}

export const ApiFormulaBulderMultipleIngredientDetails = async (props) => {
  let response

  const getDosageInputsResponseObject = {
    response_type: 'getMultipleIngredientDetailsUsingId',
    dispensary_token: props.DISPENSARY_TOKEN,
    practitioner_id: props.pracId,
    herb_id: props.Herb_Id,
    ingredient_id: props.formulaBuilderId,
  }

  try {
    response = await axios.post(props.APIURL, getDosageInputsResponseObject, {
      headers: APIHeaders,
    })
  } catch (e) {
    throw new Error(e.message)
  }
  return response?.data.results // or set initial value
}


export const ApiFormulaGlobalDiscount = async (props) => {  
  let response

  const getDosageInputsResponseObject = {
    response_type: 'GlobalDiscount',
    dispensary_token: props.DISPENSARY_TOKEN,
    practitioner_id: props.pracId,
    //price: props.price,
    formula_builder_id: props.formulaBuilderId,
  }

  try {
    response = await axios.post(props.APIURL, getDosageInputsResponseObject, {
      headers: APIHeaders,
    })
  } catch (e) {
    throw new Error(e.message)
  }
  return response?.data.results || null
};

export const checkDuplicateName = async (props) => {
  let response
  const getDuplicateName = {
    response_type: 'checkFormulaName',
    dispensary_token: props.DISPENSARY_TOKEN,
    practitioner_id: props.pracId,
    formula_builder_id: props.formulaBuilderId, 
    name: props.formulaName,
    formulaId:props.formulaId,
    reorder:props.reorder,
  }
  try {
    response = await axios.post(props.APIURL, getDuplicateName, {
      headers: APIHeaders,
    })
  } catch (e) {
    throw new Error(e.message)
  }
  return response?.data.results || null
}

export const ApigetSearchTheseIngredientsIntoAnotherIngredintTypes = async (props) => {
  let response

  const getDosageInputsResponseObject = {
    response_type: 'ingredientSearchAnotheringredintTypes',
    dispensary_token: props.DISPENSARY_TOKEN,
    practitioner_id: props.pracId,
    allowed_ingredients: props.allowed_ingredients,
    formula_builder_id: props.formulaBuilderId,
    ingredient_selection_type: props.ingredient_selection_type,
    herbs: props.herbs,
  }

  try {
    response = await axios.post(props.APIURL, getDosageInputsResponseObject, {
      headers: APIHeaders,
    })
  } catch (e) {
    throw new Error(e.message)
  }
  return response?.data.results || null
}

const sanitizeFormData = async (formData) => {
  //console.log('formData',formData)
  try {
    // Attempt deep copy to remove circular references
    const sanitizedData = JSON.parse(JSON.stringify(formData));
    return sanitizedData;
  } catch (e) {
    //console.error('Error sanitizing formData:', e.message);
    // Fallback to manually remove non-serializable fields
    return removeNonSerializableFields(formData);
  }
};

const removeNonSerializableFieldsM = (obj) => {
  if (!obj || typeof obj != 'object') return obj;

  const seen = new WeakSet();

  const traverse = (data) => {
    if (!data || typeof data != 'object') return data;

    return Object.entries(data).reduce((acc, [key, value]) => {
      if (typeof value == 'object' && value != null) {
        if (seen.has(value)) {
          //console.log('removeNonSerializableFields - Circular reference detected at key:', key);
          return acc; // Skip circular references
        }
        seen.add(value);
        acc[key] = traverse(value); // Recursively sanitize
      } else if (typeof value == 'function' || React.isValidElement(value) || typeof value == 'symbol') {
        //console.log('removeNonSerializableFields - Non-serializable value removed at key:', key, value);
      } else {
        acc[key] = value; // Keep serializable values
      }
      return acc;
    }, {});
  };

  return traverse(obj);
};
const removeNonSerializableFields = (obj) => {
  if (!obj || typeof obj != 'object') return obj;

  const seen = new WeakSet();

  const traverse = (data) => {
    if (!data || typeof data != 'object') return data;

    // Initialize an accumulator, handling arrays and objects separately
    const acc = Array.isArray(data) ? [] : {};

    Object.entries(data).forEach(([key, value]) => {
      console.log('formData-Index',key, value)
      if (typeof value == 'object' && value != null) {
        if (seen.has(value)) {
          console.warn('removeNonSerializableFields - Circular reference detected at key:', key);
          return; // Skip circular references
        }
        seen.add(value);
        acc[key] = traverse(value); // Recursively sanitize
        //acc[key] = (value);
      } else if (
        typeof value == 'function' ||
        React.isValidElement(value) ||
        typeof value == 'symbol'
      ) {
        console.warn('removeNonSerializableFields - Non-serializable value removed at key:', key, value);
        // Skip non-serializable fields
      } else {
        acc[key] = value; // Keep serializable values
      }
    });

    return acc;
  };

  return traverse(obj);
};

