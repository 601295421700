import React, {
  useEffect,
  useState,
  createContext,
  useMemo,
  useRef,
  useCallback,
} from "react";
//import Form from 'react-bootstrap/Form'
import he from "he"; // Import the HTML entity decoder library

import {
  ApiFormulaBuilderDetails,
  ApiFormulaBuilderDosagesDetails,
} from "../../../services/api/api_formula_builder";

import {
  Checkbox,
  SelectPicker,
  Tooltip,
  Whisper,
  InputNumber,
  InputPicker,
  InputGroup,
  Dropdown,
  Popover,
  Input,
  Form,
} from "rsuite";
import InfoOutlineIcon from "@rsuite/icons/InfoOutline";
import ArrowDownLineIcon from "@rsuite/icons/ArrowDownLine";
import { string } from "yup";
import { CollectionsOutlined, ErrorOutline } from "@mui/icons-material";
import FormulaSectionTextBox from "../helpers/TextBox";
import { use } from "react";
const JSONView = ({ formValue, formError }) => (
  <div className="row" style={{ marginBottom: 10 }}>
    <div className="col-12 json-tree-wrapper rs-panel">
      <div aria-expanded="false" className="rs-panel-header" tabindex="-1">
        <p className="rs-panel-title">formValue</p>
      </div>
      <div role="region" className="rs-panel-body">
        <pre>{JSON.stringify(formValue, null, 2)}</pre>
      </div>
    </div>
    <div className="col-12 json-tree-wrapper rs-panel">
      <div aria-expanded="false" className="rs-panel-header" tabindex="-1">
        <p className="rs-panel-title">formError</p>
      </div>
      <div role="region" className="rs-panel-body">
        <pre>{JSON.stringify(formError, null, 2)}</pre>
      </div>
    </div>
  </div>
);
const ErrorMessage = ({ children }) => (
  <>
    <Form.HelpText style={{ color: "red" }}>{children}</Form.HelpText>
    {/* <span style={{ color: 'red' }}>{children}</span> */}
  </>
);

const decodeEntities = (html) => {
  const doc = new DOMParser().parseFromString(he.decode(html), "text/html");
  return doc.documentElement.textContent;
  return he.decode(doc.documentElement.textContent);
};

export default function FormulaBuilderDosagesSection({
  formulaBuilderId,
  formulaDosagesType,
  activeDosages = null,
  data,
  dosageSettings = [],
  onDosagesSectionChange = null,
  blockDosagesApi = 0,
  numberDays,
  onNumberDaysChange,
  formulaDosagesSetting,
  onchange,
  formulaDeatils,
  dosageKey,
  pracId,
  display_name,
  ApplyFormValidation,
  thisFormData,
  pracDetails,
  measurmentConversions,
  ingredient_selection_type,
  dosageConverting,
  ingredients,
  dosageId,
  measurementOptions = [],
  allow_multiple_measurements = false,
  ingredient_selection = [],
  formulaTextBox,
  formulaLabelSetting,
  onFormulaWeightChange,
  fontFamily,
}) {
  const prac_formula_setting =
    pracDetails && pracDetails.formula_setting
      ? JSON.parse(pracDetails.formula_setting)
      : [];

  const current_formual_prac_setting =
    prac_formula_setting && prac_formula_setting[data.name]
      ? prac_formula_setting[data.name]
      : [];
  //const [dosageSettings, setdosageSettings] = useState([])
  const [dosageOptions, setdosageOptions] = useState([]);

  const [DosagesInputs, setDosagesInputs] = useState([]);
  const [OwnInstructions, setOwnInstructions] = useState([]);
  const [DosagesCalculation, setDosagesCalculation] = useState([]);

  const [dosagesData, setdosagesData] = useState(dosageSettings);
  const [dosagesText, setdosagesText] = useState("");
  const [dosagesQty, setdosagesQty] = useState(0);
  const [dosagesQtyConText, setDosagesQtyConText] = useState("");

  const [dosagesAmount, setDosagesAmount] = useState("");
  const [dosagesMeasurement, setDosagesMeasurement] = useState("");
  const [dosagesMeasurementArr, setDosagesMeasurementArr] = useState([]);
  const [dosagesDose, setDosagesDose] = useState("");
  const [dosagesDays, setDosagesDays] = useState("");
  const [allowOwnDosagesInstructions, setAllowOwnDosagesInstructions] =
    useState(false);
  const [pracallowOwnDosagesInstructions, setPracAllowOwnDosagesInstructions] =
    useState(false);
  const [ownDosagesInstructions, setOwnDosagesInstructions] = useState("");

  const [usePresetDosageInstructions, setUsePresetDosageInstructions] =
    useState(true);
  const [automaticallyCalculateDosages, setAutomaticallyCalculateDosages] =
    useState(false);
  const [
    allowUserToChangeAutomaticallyCalculateDosages,
    setAllowUserToChangeAutomaticallyCalculateDosages,
  ] = useState(false);

  const [formulaDosagesInput, setFormulaDosagesInput] = useState({});

  const[FORMULA_SAVED_DOSAGE_INPUT_DOSAGE_AMOUNT, setFORMULA_SAVED_DOSAGE_INPUT_DOSAGE_AMOUNT] = useState(0);

  const [formError, setFormError] = React.useState({});
  const [formValue, setFormValue] = React.useState({});
  var requirements = [
    {
      column: "DOSAGES_TYPE",
      name: "Dosages Type",
      is_required: 0,
      key: "DOSAGES_TYPE",
      allow_zero: false,
    },
    {
      column: "ALLOW_DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_INPUTS",
      name: DosagesInputs?.display_name,
      is_required: 0, //DosagesInputs.is_required,
      key: "ALLOW_DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_INPUTS",
      allow_zero: true,
    },
    {
      column: "DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_INPUTS",
      name: DosagesInputs?.display_name,
      is_required: DosagesInputs.is_required,
      key: "DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_INPUTS",
      allow_zero: false,
    },
    {
      column: "ALLOW_DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_TEXT_BOX",
      name: OwnInstructions?.display_name,
      is_required: 0, //OwnInstructions.is_required,
      key: "ALLOW_DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_TEXT_BOX",
      allow_zero: true,
    },
    {
      column: "DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_TEXT_BOX",
      name: OwnInstructions?.display_name,
      is_required: OwnInstructions.is_required,
      key: "DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_TEXT_BOX",
      allow_zero: false,
    },
    {
      column: "DOSAGE_AUTO_CALCULATE_DOSAGES_AMOUNT",
      name: "Automatically Calculate Dosages",
      is_required: 0,
      key: "DOSAGE_AUTO_CALCULATE_DOSAGES_AMOUNT",
      allow_zero: false,
    },
    {
      column: "DOSAGE_ALLOW_USER_TO_CHANGE_AUTO_CALCULATE_DOSAGES_AMOUNT",
      name: "Alow User to Changes Automatically Calculate Dosages",
      is_required: 0,
      key: "DOSAGE_ALLOW_USER_TO_CHANGE_AUTO_CALCULATE_DOSAGES_AMOUNT",
      allow_zero: false,
    },
  ];

  const measurement_options_arr = data.measurment_type_list
    ? data.measurment_type_list
    : [];

  const [dosagesQtyCal, setDosagesQtyCal] = useState(false);
  const [dosagesInputDays, setdosagesInputDays] = useState(0);

  useEffect(() => {
    var dosage_options = data.dosage_options
      ? JSON.parse(data.dosage_options)
      : [];
    if (dosageKey) {
      try {
        // Ensure dosage_options and dosageKey exist and have the expected structure
        if (dosage_options && dosage_options[dosageKey]) {
          const dosageArray = Object.values(dosage_options[dosageKey]);

          // Ensure each item has a position property before sorting
          const sortedDosageOptions = dosageArray.sort((a, b) => {
            if (a.position === undefined || b.position === undefined) {
              throw new Error(
                "Missing 'position' property in one of the items."
              );
            }
            return a.position - b.position;
          });

          // Update state
          // setdosageOptions(sortedDosageOptions); // to avoid dosage  re-render issue
          if (
            JSON.stringify(dosageOptions) != JSON.stringify(sortedDosageOptions)
          ) {
            setdosageOptions(sortedDosageOptions);
          }

          // setDosagesInputs(
          //   dosage_options[dosageKey][
          //     "DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_INPUTS"
          //   ]
          // );  // to avoid dosage  re-render issue

          // setDosagesInputs((prevInputs) => {
          const newInputs =
            dosage_options[dosageKey][
              "DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_INPUTS"
            ];
          if (JSON.stringify(DosagesInputs) != JSON.stringify(newInputs)) {
            setDosagesInputs(newInputs);
          }
          //   return prevInputs;
          // });

          if (
            dosage_options[dosageKey][
              "DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_INPUTS"
            ].is_required == "1"
          ) {
            setUsePresetDosageInstructions(true);
          } else {
            if (DosagesInputs && DosagesInputs.length != 0) {
              setUsePresetDosageInstructions(true);
            } else {
              setUsePresetDosageInstructions(false);
            }
          }
          setOwnInstructions(
            dosage_options[dosageKey][
              "DOSAGE_OPTION_DOSAGE_CALCULATION_INSTRUCTIONS_TEXT_BOX"
            ]
          );
          if (
            dosage_options[dosageKey][
              "DOSAGE_OPTION_DOSAGE_CALCULATION_INSTRUCTIONS_TEXT_BOX"
            ].is_required == "1"
          ) {
            setAllowOwnDosagesInstructions(true);
          } else {
            if (OwnInstructions) {
              setAllowOwnDosagesInstructions(true);
            } else {
              setAllowOwnDosagesInstructions(false);
            }
            // setAllowOwnDosagesInstructions(true);
          }

          setDosagesCalculation(
            dosage_options[dosageKey]["DOSAGE_OPTION_DOSAGE_CALCULATION"]
          );
        } else {
          throw new Error("Invalid dosage_options or dosageKey.");
        }
      } catch (error) {
        console.error("Error sorting dosage options:", error.message);
        // Optionally show a user-friendly message or fallback to default
        setdosageOptions([]);
      }
    }

    //console.log('dosageSettings', dosagesData, dosageSettings, dosageKey)
    /*if (formulaDosagesType == "daily") {
      if (formulaDosagesSetting) {
        if (formulaDosagesSetting.automatically_calculate_dosages == 1) {
          setAutomaticallyCalculateDosages(true);
          if (
            formulaDosagesSetting.allow_to_change_automatically_calculate_dosages ==
            1
          ) {
            setAllowUserToChangeAutomaticallyCalculateDosages(true);
          } else {
            setAllowUserToChangeAutomaticallyCalculateDosages(false);
          }
        } else {
          setAutomaticallyCalculateDosages(false);
          setAllowUserToChangeAutomaticallyCalculateDosages(false);
        }
      } else {
        setAutomaticallyCalculateDosages(false);
        setAllowUserToChangeAutomaticallyCalculateDosages(false);
      }
    } else {
      setAutomaticallyCalculateDosages(false);
      setAllowUserToChangeAutomaticallyCalculateDosages(false);
    }*/

    var AutoCalculateDosages = false;
    var ChangeAutoCalculateDosages = false;
    if (formulaDosagesType == "daily") {
      if (formulaDosagesSetting) {
        AutoCalculateDosages = formulaDosagesSetting.automatically_calculate_dosages == 1;
        ChangeAutoCalculateDosages =  AutoCalculateDosages && formulaDosagesSetting.allow_to_change_automatically_calculate_dosages == 1;
      }
    }
    

    if(formulaDeatils?.formula_extra){
      try {
        const extra = JSON.parse(formulaDeatils?.formula_extra);
        const dosageData = extra?.FORMULAS_DOSAGES;
        if (dosageData) {
          const allowPreset = Boolean(
            Number(dosageData.ALLOW_DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_INPUTS)
          );
          const allowOwnInstructions = Boolean(
            Number(dosageData.ALLOW_DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_TEXT_BOX)
          );
          
          if(DosagesInputs.is_active == "1"){
            setUsePresetDosageInstructions(allowPreset);
          }else{
            setUsePresetDosageInstructions(false);
          }
          if(OwnInstructions.is_active){
            setAllowOwnDosagesInstructions(allowOwnInstructions);
          }else{
            setAllowOwnDosagesInstructions(false);
          }
          

          if(dosageData.DOSAGES_TYPE == "daily"){
            var allowCalculate = Boolean(
              Number(dosageData.DOSAGE_AUTO_CALCULATE_DOSAGES_AMOUNT)
            );
            //console.log("allowCalculate",dosageData.DOSAGE_AUTO_CALCULATE_DOSAGES_AMOUNT,{automaticallyCalculateDosages,allowCalculate,FORMULA_SAVED_DOSAGE_INPUT_DOSAGE_AMOUNT},dosagesData.length)
            if(allowCalculate == false){              
              AutoCalculateDosages = false;            
            }
          }
        }
      } catch (error) {
        console.error("Invalid JSON in formula_extra:", error);
      }
    }else{
      if(DosagesInputs.is_active != "1"){
        setUsePresetDosageInstructions(false);
      }
      if(OwnInstructions.is_active != "1"){
        setAllowOwnDosagesInstructions(false);
      }
    }
    setAutomaticallyCalculateDosages(AutoCalculateDosages);
    setAllowUserToChangeAutomaticallyCalculateDosages(ChangeAutoCalculateDosages);  

  }, [
    formulaBuilderId,
    formulaDosagesType,
    activeDosages,
    dosageKey,
    dosageSettings,
    formulaDeatils
  ]);

  useEffect(() => {
    setdosagesData(dosageSettings);
  }, [dosageSettings]);

  useEffect(() => {
    //console.log("allowUserToChangeAutomaticallyCalculateDosages updated:", allowUserToChangeAutomaticallyCalculateDosages);
  }, [allowUserToChangeAutomaticallyCalculateDosages]);
  


  /*useEffect(() => {
    if (!formulaDeatils?.formula_extra) return; // Exit early if there's no data
    try {
      const extra = JSON.parse(formulaDeatils?.formula_extra);
      const dosageData = extra?.FORMULAS_DOSAGES;
      console.log(dosageData, "+++++++++++++++++++++++++++++++++++++");
      if (dosageData) {
        // if(dosageData.DOSAGES_TYPE == "daily"){
          // const allowCalculate = Boolean(
          //   Number(dosageData.DOSAGE_ALLOW_USER_TO_CHANGE_AUTO_CALCULATE_DOSAGES_AMOUNT)
          // );
          // setAllowUserToChangeAutomaticallyCalculateDosages(allowCalculate)
        // }
        console.log("dosageData-extra", dosageData);
        const allowPreset = Boolean(
          Number(dosageData.ALLOW_DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_INPUTS)
        );
        const allowOwnInstructions = Boolean(
          Number(dosageData.ALLOW_DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_TEXT_BOX)
        );
        setAllowOwnDosagesInstructions(allowOwnInstructions);
        setUsePresetDosageInstructions(allowPreset);
        if(dosageData.DOSAGES_TYPE == "daily"){
          var allowCalculate = Boolean(
            Number(dosageData.DOSAGE_AUTO_CALCULATE_DOSAGES_AMOUNT))
            
          if(allowCalculate == false){          
            setTimeout(() => {
              setAutomaticallyCalculateDosages(false)
              //setTimeout(()=>{ setAutomaticallyCalculateDosages(false)},200)
              console.log("allowCalculate",{automaticallyCalculateDosages,allowCalculate,FORMULA_SAVED_DOSAGE_INPUT_DOSAGE_AMOUNT},dosagesData.length)
              // var final_amount = FORMULA_SAVED_DOSAGE_INPUT_DOSAGE_AMOUNT
              // if (Object.values(dosagesData).length !== 0) {
              //   Object.values(dosagesData).forEach((i) => {
              //     var thisVal = Number(i.value);
              //     if (
              //       i.key_value == "DOSAGE_INPUT_DOSAGE_AMOUNT" &&
              //       thisVal != final_amount
              //     ) {                  
              //       HandelDosagesSettings(final_amount, i.id);
              //     }
              //   });
              // }
            }, 1000);
            
          }
        }
        
        
      }
    } catch (error) {
      console.error("Invalid JSON in formula_extra:", error);
    }
  }, [formulaDeatils]);*/

  // auto calculate Dosages amount only for daily dosages
  useEffect(() => {
    if (
      automaticallyCalculateDosages == true &&
      usePresetDosageInstructions == true &&
      //allowUserToChangeAutomaticallyCalculateDosages == true &&
      ([
        "ONE_INGREDIENT_TYPE_ONE_MEASUREMENT_TYPE",
        "MULTIPLE_INGREDIENT_TYPES_ONE_MEASUREMENT_TYPE_FOR_ALL_INGREDIENTS",
      ].includes(data.ingredient_selection_type) ||
        ([
          "ONE_INGREDIENT_TYPE_MULTIPLE_MEASUREMENT_TYPES",
          "MULTIPLE_INGREDIENT_TYPES_MULTIPLE_MEASUREMENT_TYPES_PER_INGREDIENT",
        ].includes(data.ingredient_selection_type) &&
          allow_multiple_measurements == 0))
    ) {
      var all_ingredients = [];
      if (ingredients && ingredients.length != 0) {
        ingredients.map((item) => {
          if (
            item.ingredient_id != "" &&
            item.formula_dosage_column_qty_received
          ) {
            var temp = {
              id: item.ingredient_id,
              qty: Number(item.formula_dosage_column_qty_received) || 0,
              unit: item.default_measurment,
            };
            all_ingredients.push(temp);
          }
        });
      }

      const sumQty = all_ingredients.reduce(
        (total, currentItem) => Number(total) + Number(currentItem.qty),
        0
      );
      var diviedBy = (Number(dosagesDose) || 1) * (Number(numberDays) || 1);
      var final_amount = Number(sumQty / diviedBy).toFixed(3);
      console.log(
        "automaticallyCalculateDosages-1",
        sumQty,
        diviedBy,
        final_amount,
        dosagesMeasurementArr
      );
      if (dosagesMeasurementArr) {
        var thisCon = Number(dosagesMeasurementArr?.equal_to);
        // If thisCon is 0 or not a finite number, set it to 1
        thisCon = thisCon == 0 ? 1 : thisCon;
        final_amount = Number(final_amount / Number(thisCon)).toFixed(3);
      }
      console.log(
        "automaticallyCalculateDosages-2",
        sumQty,
        diviedBy,
        final_amount,
        dosagesMeasurementArr
      );
      // Check if finalQty is Infinity or NaN, and set it to 0 if so
      if (!isFinite(final_amount)) {
        final_amount = 0;
      }

      if (Object.values(dosagesData).length !== 0) {
        Object.values(dosagesData).forEach((i) => {
          var thisVal = Number(i.value);
          if (
            i.key_value == "DOSAGE_INPUT_DOSAGE_AMOUNT" &&
            thisVal != final_amount
          ) {
            console.log(
              "automaticallyCalculateDosages-3",
              sumQty,
              diviedBy,
              final_amount
            );
            HandelDosagesSettings(final_amount, i.id);
          }
        });
      }
    }
  }, [
    formulaBuilderId,
    numberDays,
    dosagesDose,
    dosagesData,
    //formulaDosagesSetting,
    formulaDosagesType,
    // allowUserToChangeAutomaticallyCalculateDosages,
    automaticallyCalculateDosages,
    usePresetDosageInstructions,
    ingredients,
  ]);
  // update formula saved data
  useEffect(() => {
    if (
      formulaDeatils &&
      formulaDeatils.id &&
      formulaDeatils.formula_t &&
      formulaDeatils.formula_t == dosageId
    ) {
      let dosage_input = [];
      if (formulaDeatils && formulaDeatils?.dosage_input) {
        try {
          dosage_input = JSON.parse(formulaDeatils.dosage_input);
        } catch (error) {
          console.error(
            "Failed to parse dosage_input:line 358",
            formulaDeatils?.dosage_input,
            error
          );
          dosage_input = []; // Fallback to empty array on parse failure
        }
      }

      const dosagesInput = {};

      dosage_input.forEach((item) => {
        //dosagesInput[item.id] = item.value
        if (item && item.id !== undefined) {
          dosagesInput[item.id] = item.value;
        }
      });
      var updatedDosagesData = [];
      Object.values(dosageSettings).map((array, key) => {
        if (
          dosagesInput.length != 0 &&
          dosagesInput[array.id] != "" &&
          dosagesInput[array.id] != undefined
        ) {
          array.value = dosagesInput[array.id];
          if (array.key_value == "DOSAGE_INPUT_NUMBER_OF_DAYS") {
            if (dosageConverting == true) {
              onNumberDaysChange(numberDays);
              console.log("DOSAGE_INPUT_NUMBER_OF_DAYS", numberDays);
            } else {
              onNumberDaysChange(array.value);
              setTimeout(() => {
                onNumberDaysChange(array.value);
              }, 500);
              setTimeout(() => {
                onNumberDaysChange(array.value);
              }, 800);
              console.log("DOSAGE_INPUT_NUMBER_OF_DAYS", array.value);
            }
          }
          if(array.key_value == "DOSAGE_INPUT_DOSAGE_AMOUNT"){
            setFORMULA_SAVED_DOSAGE_INPUT_DOSAGE_AMOUNT(array.value)
          }
        }
        updatedDosagesData.push(array);
      });
      if (updatedDosagesData.length != 0) {
        setdosagesData(updatedDosagesData);
      }

      var do_not_use_on_label = formulaDeatils.do_not_use_on_label
        ? formulaDeatils.do_not_use_on_label
        : usePresetDosageInstructions;
      setUsePresetDosageInstructions(do_not_use_on_label);

      var allow_additional_info_formula =
        formulaDeatils.allow_additional_info_formula
          ? formulaDeatils.allow_additional_info_formula
          : allowOwnDosagesInstructions;
      setAllowOwnDosagesInstructions(allow_additional_info_formula);

      var own_instructions = formulaDeatils.additional_info_formula
        ? formulaDeatils.additional_info_formula
        : ownDosagesInstructions;

      var maxLenght = OwnInstructions ? parseInt(OwnInstructions.maxlength) : 0;
      if (maxLenght && maxLenght != 0 && own_instructions.length > maxLenght) {
        //console.log('textarea',textValue,maxLenght,textValue.length)
        setOwnDosagesInstructions(own_instructions.slice(0, maxLenght));
      } else {
        setOwnDosagesInstructions(own_instructions);
      }
      //setOwnDosagesInstructions(own_instructions)
    }

    //updatePreviewText()
  }, [dosageSettings]);
  // update Preview text
  useEffect(() => {
    updatePreviewText(dosagesData);

    if (Array.isArray(dosagesData)) {
      const foundValue =
        dosagesData
          .filter((item) => item?.key_value == "DOSAGE_INPUT_NUMBER_OF_DAYS")
          .map((item) => item?.value)[0] || 0; // Ensure a default value (0) if no match

      if (foundValue && foundValue != dosagesInputDays) {
        setdosagesInputDays(foundValue);
      }
    }
  }, [dosagesData]);
  // recurcive update number of days
  useEffect(() => {
    //setdosagesData(dosageSettings)
    setdosagesData((prevDosagesData) => {
      const updatedDosagesData = Object.values(prevDosagesData).map((array) => {
        if (array.key_value == "DOSAGE_INPUT_NUMBER_OF_DAYS") {
          return {
            ...array,
            value: numberDays,
          };
        }
        return array;
      });
      // Perform any additional logic here if needed
      updatePreviewText(updatedDosagesData);
      return updatedDosagesData;
    });

    //onNumberDaysChange(numberDays)
  }, [numberDays]);
  // send data to parent component
  useEffect(() => {
    onchange(
      usePresetDosageInstructions,
      dosagesData,
      allowOwnDosagesInstructions,
      ownDosagesInstructions,
      dosagesText,
      automaticallyCalculateDosages,
      allowUserToChangeAutomaticallyCalculateDosages
    );
  }, [
    usePresetDosageInstructions,
    dosagesData,
    allowOwnDosagesInstructions,
    ownDosagesInstructions,
    automaticallyCalculateDosages,
    allowUserToChangeAutomaticallyCalculateDosages
  ]);
  // update formulaDosagesSetting
  useEffect(() => {
    var selected_dosages_mode_key = "";
    var all_own_ins = 0;
    var own_ins_text = "";
    var match_quantity_received_with_dosage = false;
    // const {
    //   is_own_dosage_instructions,
    //   text_own_dosage_instructions,
    //   formula_option,
    //   match_quantity_received_with_dosage
    // } = current_formual_prac_setting || {};
    const {
      is_own_dosage_instructions = false, // Default to `false` if undefined
      text_own_dosage_instructions = "", // Default to an empty string if undefined
      formula_option = null, // Default to `null` if undefined
      //match_quantity_received_with_dosage = false // Default to `false` if undefined
    } = current_formual_prac_setting || {};

    if (formulaDosagesSetting) {
      selected_dosages_mode_key = formulaDosagesSetting.key_value;
    }
    var is_own_dosage_instructions_setting = is_own_dosage_instructions
      ? JSON.parse(is_own_dosage_instructions)
      : [];
    var text_own_dosage_instructions_setting = text_own_dosage_instructions
      ? JSON.parse(text_own_dosage_instructions)
      : [];

    if (
      is_own_dosage_instructions_setting &&
      is_own_dosage_instructions_setting[selected_dosages_mode_key] != "" &&
      is_own_dosage_instructions_setting[selected_dosages_mode_key] != null &&
      is_own_dosage_instructions_setting[selected_dosages_mode_key] != undefined
    ) {
      all_own_ins =
        is_own_dosage_instructions_setting[selected_dosages_mode_key];
      setPracAllowOwnDosagesInstructions(all_own_ins == 1 ? true : false);
      // console.log('OwnInstructions-prac',OwnInstructions,OwnInstructions.is_active ,pracallowOwnDosagesInstructions)
      // if (
      //   OwnInstructions &&
      //   OwnInstructions.is_active == 1 &&
      //   all_own_ins == 1 &&
      //   allowOwnDosagesInstructions != true
      // ) {
      //   console.log('---------if--------')
      //   setAllowOwnDosagesInstructions(true);
      // } else if (allowOwnDosagesInstructions != false) {
      //   console.log('--------else-if--------')
      //   setAllowOwnDosagesInstructions(false);
      // }
    }
    if (
      text_own_dosage_instructions_setting &&
      text_own_dosage_instructions_setting[selected_dosages_mode_key] != "" &&
      text_own_dosage_instructions_setting[selected_dosages_mode_key] != null &&
      text_own_dosage_instructions_setting[selected_dosages_mode_key] !=
        undefined
    ) {
      own_ins_text =
        text_own_dosage_instructions_setting[selected_dosages_mode_key];
      setOwnDosagesInstructions(own_ins_text);
    }
    var prac_formula_option_setting = formula_option
      ? JSON.parse(formula_option)
      : [];

    if (
      prac_formula_option_setting &&
      selected_dosages_mode_key &&
      prac_formula_option_setting[selected_dosages_mode_key] != "" &&
      prac_formula_option_setting[selected_dosages_mode_key] != null &&
      prac_formula_option_setting[selected_dosages_mode_key] != undefined
    ) {
      var selected_option_setting =
        prac_formula_option_setting[selected_dosages_mode_key];
      if (selected_option_setting) {
        const { match_quantity_received_with_dosage = false } =
          selected_option_setting || {};
        //console.log('dosagesInputQty-match_quantity_received_with_dosage',match_quantity_received_with_dosage)
        setDosagesQtyCal(match_quantity_received_with_dosage);
      }
    }

    /*if (
      prac_formula_option_setting &&
      prac_formula_option_setting[selected_dosages_mode_key] != "" &&
      prac_formula_option_setting[selected_dosages_mode_key] != null &&
      prac_formula_option_setting[selected_dosages_mode_key] != undefined &&
      selected_dosages_mode_key == "FORMULA_DOSAGE_MODE_DAILY"
    ) {
      var selected_option_setting =
        prac_formula_option_setting[selected_dosages_mode_key];
      // console.log(
      //   "selected_option_setting",
      //   selected_option_setting,
      //   selected_dosages_mode_key
      // );

      if (
        formulaDosagesSetting.automatically_calculate_dosages == 1 &&
        formulaDosagesSetting.allow_to_change_automatically_calculate_dosages ==
          1
      ) {
        const {
          default_bags,
          default_days,
          prac_allow_to_change_automatic_calculattion, // prac setting remove this setting
          default_total_qty_received,
        } = selected_option_setting || {};
        
        if (prac_allow_to_change_automatic_calculattion == 1) {
          setAllowUserToChangeAutomaticallyCalculateDosages(true);
        } else {
          setAllowUserToChangeAutomaticallyCalculateDosages(false);
        }
        
      } else if (
        formulaDosagesSetting.automatically_calculate_dosages == 1 &&
        formulaDosagesSetting.allow_to_change_automatically_calculate_dosages !=
          1
      ) {
        setAllowUserToChangeAutomaticallyCalculateDosages(true);
      }else{
        setAllowUserToChangeAutomaticallyCalculateDosages(false);
      }
    }*/
   
  }, [formulaBuilderId, formulaDosagesSetting, activeDosages]);


  // this function has error
  const HandelDosagesSettingsM = (value, field) => {
    try {
      const updatedDosagesData = dosageSettings.map((item) => {
        // Update the specific field
        if (item.id === field && item.value !== value) {
          return { ...item, value: value };
        }
        return item;
      });

      // // Check if the data has changed
      const isDataChanged = !updatedDosagesData.every(
        (item, index) => item.value === dosagesData[index]?.value // Compare only the values
      );
      if (isDataChanged) {
        setdosagesData(updatedDosagesData);

        // if (
        //   JSON.stringify(updatedDosagesData) !== JSON.stringify(dosagesData)
        // ) {
        //   setdosagesData(updatedDosagesData);
        // }
        updatePreviewText(updatedDosagesData);
      }
    } catch (error) {
      console.error("Error updating dosages settings:", error);
    }
  };
  // this function has working
  const HandelDosagesSettingsM2 = (value, field) => {
    try {
      // Update the dosages data
      setdosagesData((prevDosagesData) => {
        const updatedDosagesData = prevDosagesData.map((item) => {
          if (item.id == field && item.value != value) {
            return { ...item, value };
          }
          return item;
        });

        // Check if data has changed
        const isDataChanged = !updatedDosagesData.every(
          (item, index) => item.value == prevDosagesData[index]?.value
        );

        if (isDataChanged) {
          updatePreviewText(updatedDosagesData);
          return updatedDosagesData; // Update the state
        }

        return prevDosagesData; // Return the previous state if no change
      });
    } catch (error) {
      console.error("Error updating dosages settings:", error);
    }
  };
  // improved version
  const HandelDosagesSettings = (value, field) => {
    try {
      // Update the dosages data
      // setdosagesData((prevDosagesData) => {
      //   // Map through the previous state to create the updated data
      //   const updatedDosagesData = prevDosagesData.map((item) => {
      //     if (item.id == field && item.value != value) {
      //       return { ...item, value }; // Update the specific field
      //     }
      //     return item; // Keep the item unchanged
      //   });

      //   // Check if the updated data is identical to the previous data
      //   const isDataChanged = updatedDosagesData.some(
      //     (item, index) => item.value != prevDosagesData[index]?.value
      //   );

      //   if (isDataChanged) {
      //     updatePreviewText(updatedDosagesData); // Update preview if data changes
      //     return updatedDosagesData; // Update the state
      //   }

      //   return prevDosagesData; // Return previous state if no change
      // });
      setdosagesData((prevDosagesData) => {
        const updatedDosagesData = prevDosagesData.map((item) => {
          if (item.id == field && item.value != value) {
            return { ...item, value };
          }
          return item;
        });

        const isDataChanged = updatedDosagesData.some(
          (item, index) => item.value != prevDosagesData[index]?.value
        );

        if (isDataChanged) {
          updatePreviewText(updatedDosagesData);
          return updatedDosagesData;
        }
        return prevDosagesData;
      });
    } catch (error) {
      console.error("Error updating dosages settings:", error);
    }
  };

  var finalDosageType = activeDosages ? activeDosages : formulaDosagesType;

  // on-change formula type
  const updatePreviewText = (array) => {
    if(usePresetDosageInstructions==false) {return false}
    setdosagesText("");
    let currentDosagesText = "";
    if (Object.values(array).length !== 0) {
      Object.values(array).forEach((i) => {
        if (i.drop_type === "placer") {
          currentDosagesText += i.name + " ";
        } else if (i.drop_type === "text" || i.drop_type === "number") {
          currentDosagesText += i?.value + " ";
        } else if (i.drop_type == "selectBox") {
          var dosageValues = i.dosageValues ? JSON.parse(i.dosageValues) : [];
          // console.log("dosageValues-preview", i, dosageValues);
          var allowed_option = dosageValues.map((item) => item.name);
          let exist = 0;
          if (i?.value && i?.value != undefined && i?.value != "") {
            currentDosagesText += i?.value + " ";
          }
        }
      });
      setdosagesText(currentDosagesText);
    }
    calculateDosage(array);
  };

  const calculateDosage = (array) => {
    if(usePresetDosageInstructions==false) {return false}
    let DOSAGE_INPUT_MEASUREMENT = "";
    let DOSAGE_INPUT_DOSAGE_AMOUNT = 1;
    let DOSAGE_INPUT_DOSES_PER_DAY = 1;
    let DOSAGE_INPUT_NUMBER_OF_DAYS = 1;
    let DOSAGE_INPUT_MEASUREMENT_CONVERION = 0;
    let DOSAGE_INPUT_DEFAULT_MEASUREMENT = "";
    let DOSAGE_INPUT_DEFAULT_MEASUREMENT_ID = "";
    let DOSAGE_INPUT_MEASUREMENT_ID = "";
    let DOSAGE_CONVERION_TEXT = "";

    if (Object.values(array).length !== 0) {
      Object.values(array).forEach((i) => {
        if (i.key_value == "DOSAGE_INPUT_MEASUREMENT") {
          var selectedUnit = "";
          //var dosageValues = JSON.parse(i?.dosageValues)
          var dosageValues = i.dosageValues ? JSON.parse(i.dosageValues) : [];
          var allowed_option = dosageValues.map((item) => item.name);
          var selected_value = dosageValues.find(
            (dosage) => dosage.name == i.value
          );

          if (i.default_value != null && i.default_value != 0) {
            allowed_option.push(i.default_value);
          }
          // console.log(
          //   "DOSAGE_INPUT_MEASUREMENT",
          //   selected_value,
          //   default_value,
          //   allowed_option
          // );
          /*measurement_options_arr.forEach((val) => {
            if (allowed_option.includes(val.id)) {
              if (val.id == i.value) {
                selectedUnit = val.display_name
                DOSAGE_INPUT_MEASUREMENT_ID = val.id
              }
              if (default_value && default_value.name == val.id) {
                DOSAGE_INPUT_DEFAULT_MEASUREMENT = val.display_name
                DOSAGE_INPUT_DEFAULT_MEASUREMENT_ID = val.id
              }
            }
          })*/
          if (
            ingredient_selection_type ==
              "ONE_INGREDIENT_TYPE_ONE_MEASUREMENT_TYPE" ||
            ingredient_selection_type ==
              "MULTIPLE_INGREDIENT_TYPES_ONE_MEASUREMENT_TYPE_FOR_ALL_INGREDIENTS"
          ) {
            var allow_measurement_option = data
              ? data.default_measuremt_type
              : measurementOptions?.default_measurement;

            // console.log(
            //   "dosagesQtyCal-allow_measurement_option",
            //   allow_measurement_option,
            //   data.default_measuremt_type
            // );
            var default_value = dosageValues.find(
              (dosage) => dosage.set_default == 1
            );
            if (!i.value && default_value && default_value.length != 0) {
              i.value = default_value?.name;
            }
            dosageValues?.forEach((ii) => {
              if (ii.name == i.value) {
                selectedUnit = ii.name;
                selected_value = ii;
                DOSAGE_INPUT_MEASUREMENT_ID = ii.name;
              }
              measurement_options_arr.forEach((val) => {
                if (allow_measurement_option == val.id) {
                  DOSAGE_INPUT_DEFAULT_MEASUREMENT = val.display_name;
                  DOSAGE_INPUT_DEFAULT_MEASUREMENT_ID = val.id;
                }
              });
            });
          } else if (
            ingredient_selection_type ==
              "ONE_INGREDIENT_TYPE_MULTIPLE_MEASUREMENT_TYPES" &&
            allow_multiple_measurements == false
          ) {
            var allow_measurement_option =
              measurementOptions?.default_measurement;
            var default_value = dosageValues.find(
              (dosage) => dosage.set_default == 1
            );
            if (!i.value && default_value && default_value.length != 0) {
              i.value = default_value?.name;
            }
            dosageValues.forEach((ii) => {
              if (allow_measurement_option == ii.unit_id) {
                if (ii.name == i.value) {
                  selectedUnit = ii.name;
                  selected_value = ii;
                  DOSAGE_INPUT_MEASUREMENT_ID = ii.name;
                }
              }
            });
            measurement_options_arr.forEach((val) => {
              if (allow_measurement_option == val.id) {
                DOSAGE_INPUT_DEFAULT_MEASUREMENT = val.display_name;
                DOSAGE_INPUT_DEFAULT_MEASUREMENT_ID = val.id;
              }
            });
          } else if (
            ingredient_selection_type ==
              "MULTIPLE_INGREDIENT_TYPES_MULTIPLE_MEASUREMENT_TYPES_PER_INGREDIENT" &&
            allow_multiple_measurements == false
          ) {
            var allow_measurement_option = [];
            Object.entries(measurementOptions).map(([key, value1]) => {
              if (ingredient_selection.includes(value1.ingredient_id)) {
                allow_measurement_option.push(value1.default_measurement);
              }
            });
            dosageValues.forEach((ii) => {
              if (
                allow_measurement_option.includes(ii.unit_id) &&
                ii.is_active == 1
              ) {
                if (ii.name == i.value) {
                  selectedUnit = ii.name;
                  selected_value = ii;
                  DOSAGE_INPUT_MEASUREMENT_ID = ii.name;
                }
              }
            });
            measurement_options_arr.forEach((val) => {
              if (selected_value?.unit_id == val.id) {
                DOSAGE_INPUT_DEFAULT_MEASUREMENT = val.display_name;
                DOSAGE_INPUT_DEFAULT_MEASUREMENT_ID = val.id;
              }
            });
            // console.log("dosageCal-default_value", selected_value);
          } else {
            var default_value = dosageValues.find(
              (dosage) => dosage.set_default == 1
            );
            if (!i.value && default_value && default_value.length != 0) {
              i.value = default_value?.name;
            }
            dosageValues?.forEach((ii) => {
              if (ii.name == i.value) {
                selectedUnit = ii.name;
                selected_value = ii;
                DOSAGE_INPUT_MEASUREMENT_ID = ii.name;
              }
              // console.log('dosagesQtyCal-default_value',default_value)
              if (default_value && default_value.name == ii.name) {
                DOSAGE_INPUT_DEFAULT_MEASUREMENT = ii.name;
                DOSAGE_INPUT_DEFAULT_MEASUREMENT_ID = ii.name;
              }
            });
          }

          DOSAGE_INPUT_MEASUREMENT = selectedUnit;
          setDosagesMeasurementArr(selected_value);
          if (selected_value && selected_value.equal_to) {
            DOSAGE_INPUT_MEASUREMENT_CONVERION = selected_value.equal_to;
          }
        }
        if (i.key_value == "DOSAGE_INPUT_DOSAGE_AMOUNT") {
          DOSAGE_INPUT_DOSAGE_AMOUNT = Number(i.value) || 1;
        }
        if (i.key_value == "DOSAGE_INPUT_DOSES_PER_DAY") {
          DOSAGE_INPUT_DOSES_PER_DAY = Number(i.value) || 1;
        }
        if (i.key_value == "DOSAGE_INPUT_NUMBER_OF_DAYS") {
          DOSAGE_INPUT_NUMBER_OF_DAYS = Number(i.value) || 1;
        }
      });
    }
    if (!isFinite(DOSAGE_INPUT_DOSAGE_AMOUNT)) {
      DOSAGE_INPUT_DOSAGE_AMOUNT = 0;
    }
    if (!isFinite(DOSAGE_INPUT_DOSES_PER_DAY)) {
      DOSAGE_INPUT_DOSES_PER_DAY = 0;
    }
    if (!isFinite(DOSAGE_INPUT_NUMBER_OF_DAYS)) {
      DOSAGE_INPUT_NUMBER_OF_DAYS = 0;
    }
    var finalQty =
      Number(DOSAGE_INPUT_DOSAGE_AMOUNT) *
      Number(DOSAGE_INPUT_DOSES_PER_DAY) *
      Number(DOSAGE_INPUT_NUMBER_OF_DAYS);
    // Check if finalQty is Infinity or NaN, and set it to 0 if so
    if (!isFinite(finalQty)) {
      finalQty = 0;
    }
    // console.log('finalQty-final', {
    //   finalQty,
    //   dosageAmount: Number(DOSAGE_INPUT_DOSAGE_AMOUNT) || 0,
    //   dosesPerDay: Number(DOSAGE_INPUT_DOSES_PER_DAY) || 0,
    //   numberOfDays: Number(DOSAGE_INPUT_NUMBER_OF_DAYS) || 0
    // });

    setDosagesAmount(Number(DOSAGE_INPUT_DOSAGE_AMOUNT));
    setDosagesDose(Number(DOSAGE_INPUT_DOSES_PER_DAY));
    setDosagesDays(Number(DOSAGE_INPUT_NUMBER_OF_DAYS));

    if (
      ingredient_selection_type == "ONE_INGREDIENT_TYPE_ONE_MEASUREMENT_TYPE" ||
      ((ingredient_selection_type ==
        "ONE_INGREDIENT_TYPE_MULTIPLE_MEASUREMENT_TYPES" ||
        ingredient_selection_type ==
          "MULTIPLE_INGREDIENT_TYPES_MULTIPLE_MEASUREMENT_TYPES_PER_INGREDIENT") &&
        allow_multiple_measurements == false)
    ) {
      setDosagesMeasurement(String(DOSAGE_INPUT_DEFAULT_MEASUREMENT)); // dosages input default measurement in admin
      if (DOSAGE_INPUT_DEFAULT_MEASUREMENT_ID && DOSAGE_INPUT_MEASUREMENT_ID) {
        var this_con = DOSAGE_INPUT_MEASUREMENT_CONVERION
          ? Number(DOSAGE_INPUT_MEASUREMENT_CONVERION)
          : 0;
        if (Number(this_con) && Number(this_con) != 1) {
          var total = Number(finalQty) * Number(this_con);
          setdosagesQty(Number(total.toFixed(2)));
          if (DOSAGE_INPUT_MEASUREMENT && DOSAGE_INPUT_DEFAULT_MEASUREMENT) {
            DOSAGE_CONVERION_TEXT =
              " 1 " +
              " " +
              DOSAGE_INPUT_MEASUREMENT +
              " = " +
              this_con +
              " " +
              DOSAGE_INPUT_DEFAULT_MEASUREMENT +
              "\n";
          } else {
            DOSAGE_CONVERION_TEXT = "";
          }
        } else {
          let result = [];
          var from_unit = DOSAGE_INPUT_MEASUREMENT_ID;
          var to_unit = DOSAGE_INPUT_DEFAULT_MEASUREMENT_ID;
          // global conversion
          // if (measurmentConversions.length != 0) {
          //   for (const key in measurmentConversions) {
          //     const conversion = measurmentConversions[key]
          //     if (
          //       (conversion.from_unit == from_unit &&
          //         conversion.to_unit == to_unit) ||
          //       (conversion.from_unit == to_unit &&
          //         conversion.to_unit == from_unit)
          //     ) {
          //       result = conversion
          //       break
          //     }
          //   }
          // }
          if (result.length != 0 && result != null) {
            if (
              result != null &&
              result.from_unit == from_unit &&
              result.to_unit == to_unit
            ) {
              var con =
                parseFloat(result.from_value) / parseFloat(result.to_value);
              var total = Number(finalQty) * Number(con);
              setdosagesQty(Number(total.toFixed(2)));
              // DOSAGE_CONVERION_TEXT =
              //   result.from_value +
              //   DOSAGE_INPUT_MEASUREMENT +
              //   ' converts to ' +
              //   result.to_value +
              //   DOSAGE_INPUT_DEFAULT_MEASUREMENT
            } else if (
              result != null &&
              result.from_unit == to_unit &&
              result.to_unit == from_unit
            ) {
              var con =
                parseFloat(result.to_value) / parseFloat(result.from_value);
              var total = Number(finalQty) * Number(con);
              setdosagesQty(Number(total.toFixed(2)));

              DOSAGE_CONVERION_TEXT =
                result.to_value +
                " " +
                DOSAGE_INPUT_MEASUREMENT +
                " converts to " +
                result.from_value +
                " " +
                DOSAGE_INPUT_DEFAULT_MEASUREMENT;
            }
          } else {
            setDosagesMeasurement(DOSAGE_INPUT_MEASUREMENT);
            //DOSAGE_CONVERION_TEXT = DOSAGE_INPUT_MEASUREMENT
            //DOSAGE_CONVERION_TEXT = '1' + DOSAGE_INPUT_MEASUREMENT
            // ' converts to 1' +
            // DOSAGE_INPUT_DEFAULT_MEASUREMENT +
            // '<small> ( No Conversion Found )</small>'
            setdosagesQty(Number(finalQty.toFixed(2)));
          }
        }
      } else {
        setdosagesQty(Number(finalQty.toFixed(2)));
      }
      setDosagesQtyConText(DOSAGE_CONVERION_TEXT);
    } else {
      setDosagesMeasurement(String(DOSAGE_INPUT_MEASUREMENT)); // dosages input selected measurement
      setdosagesQty(Number(finalQty.toFixed(2)));
      setDosagesQtyConText("");
    }
  };

  useEffect(() => {
    if(usePresetDosageInstructions==true) {
      updatePreviewText(dosagesData);
    }
    
  }, [measurementOptions]);

  const disableKeys = (event) => {
    // Allow numbers, dots, and specific key codes for control keys
    if (
      !(event.key === "." || (event.key >= "0" && event.key <= "9")) &&
      ![8, 9, 37, 39, 46].includes(event.keyCode)
    ) {
      event.preventDefault();
    }
  };
  useEffect(() => {
    formValidation();
  }, [
    usePresetDosageInstructions,
    dosagesData,
    allowOwnDosagesInstructions,
    ownDosagesInstructions,
    numberDays,
    dosageSettings,
    formulaBuilderId,
  ]);

  // apply form validation action
  useEffect(() => {
    if (ApplyFormValidation == true) {
      //formValidation()
    }
  }, [ApplyFormValidation]);

  // dosages convertion
  useEffect(() => {
    if (dosageConverting == true && usePresetDosageInstructions==true) {
      DosagesConverting();
    }
  }, [dosageConverting]);

  const formValidation = () => {
    formValue["DOSAGES_TYPE"] = finalDosageType;
    formValue["ALLOW_DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_INPUTS"] =
      usePresetDosageInstructions;
    
    formValue["ALLOW_DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_TEXT_BOX"] =
      allowOwnDosagesInstructions;
    formValue["DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_TEXT_BOX"] =
      ownDosagesInstructions;
    formValue["DOSAGE_AUTO_CALCULATE_DOSAGES_AMOUNT"] =
      automaticallyCalculateDosages;
    formValue["DOSAGE_ALLOW_USER_TO_CHANGE_AUTO_CALCULATE_DOSAGES_AMOUNT"] =
      allowUserToChangeAutomaticallyCalculateDosages;
    formValue["DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_INPUTS"] = dosagesData
      ? dosagesData
      : [];
    var Final_array = [];

    Object.values(requirements).forEach((field) => {
      const ikey = field.key;
      var i = field;
      var isRequired = field.is_required == 1;
      var fieldValue = formValue[ikey];
      // Modify the required flag based on specific fields
      if (
        ikey == "DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_INPUTS" &&
        formValue["ALLOW_DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_INPUTS"] == true
      ) {
        isRequired = 1;
      } else if (
        ikey == "DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_TEXT_BOX" &&
        formValue["ALLOW_DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_TEXT_BOX"] == true
      ) {
        isRequired = 1;
      } else {
        isRequired = 0;
      }

      if (isRequired == 1) {
        if (ikey == "DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_INPUTS") {
          if (typeof fieldValue == "object" && fieldValue.length == 0) {
            if (!formError[ikey]) {
              formError[ikey] = {}; // Initialize if not defined
            }

            formError[ikey]["error"] = `${field.name} are required.`;
          } else if (typeof fieldValue == "object" && fieldValue.length != 0) {
            if (!formError[ikey]) {
              formError[ikey] = {}; // Initialize if not defined
            } else {
              formError[ikey] = {};
            }
            formError[ikey]["error"] = "";
            fieldValue.forEach((item) => {
              if (item.drop_type != "placer" && item.set_required == 1) {
                const thisVal = String(item.value).trim();
                formError[ikey][item.id] =
                  thisVal == "" ||
                  thisVal == 0 ||
                  thisVal == null ||
                  thisVal == undefined
                    ? `${item.name} are required.`
                    : "";
              }
            });
          }
        } else if (ikey === "DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_TEXT_BOX") {
          // Validation for checkbox and textarea
          if (OwnInstructions.is_required == 1) {
            if (
              String(formValue[ikey]).trim() == "" ||
              formValue[ikey] == null ||
              formValue[ikey] == 0 ||
              formValue[ikey] == undefined
            ) {
              formError[ikey] = `${OwnInstructions.name} are required.`;
            } else {
              formError[ikey] = "";
            }
          } else {
            formError[ikey] = "";
          }
        } else {
          if (i.allow_zero == true) {
            if (
              String(formValue[ikey]).trim() == "" ||
              formValue[ikey] == null ||
              formValue[ikey] == undefined
            ) {
              // console.log(i.key, formValue[i.key], 1);
              formError[ikey] = `${i.name} are required.`;
            } else {
              formError[ikey] = "";
            }
          }
        }
      } else {
        formError[ikey] = "";
      }

      const temp = {
        ...field,
        is_required: isRequired,
        value: formValue[ikey],
        error: formError[ikey],
      };
      Final_array.push(temp);
    });
    // console.log("FORMULAS_DOSAGES", Final_array);
    thisFormData(Final_array, "FORMULAS_DOSAGES");
  };

  const DosagesConverting = () => {
    // console.log(
    //   "DosagesConverting",
    //   formulaDosagesType,
    //   activeDosages,
    //   formulaDosagesSetting
    // );
  };

  useEffect(() => {
    //console.log('dosagesInputQty-dosages-start',dosagesQty,dosagesQtyCal)
    if (dosagesQtyCal && dosagesQtyCal == true && usePresetDosageInstructions==true) {
      // if dosages mode per then update the dosages input qty
      onFormulaWeightChange(dosagesQty);
    }
    //if(DOSAGE_INPUT_NUMBER_OF_DAYS!=numberDays){
    //console.log('DOSAGE_INPUT_NUMBER_OF_DAYS',{DOSAGE_INPUT_NUMBER_OF_DAYS,numberDays})
    //onNumberDaysChange(Number(DOSAGE_INPUT_NUMBER_OF_DAYS))
    //}
  }, [dosagesQtyCal, dosagesQty, numberDays]);

  useEffect(() => {
    if (dosagesInputDays != numberDays) {
      //onNumberDaysChange(dosagesInputDays) // re-render issue
    }
  }, [dosagesInputDays]);

  const bgStyle = formulaLabelSetting?.bgStyle || {};
  const inputSettings = formulaLabelSetting?.inputSettings || {};
  const textStyle = {
    fontSize: inputSettings?.fontSize,
    color: inputSettings?.color,
  };
  var dropdownStyle = {
    ...inputSettings,
    ...bgStyle,
  };

  const inputElement = document.querySelector(".rs-input-number");
  // Calculate the minWidth and left position
  // const minWidth = (inputElement?.offsetWidth || 0);
  const MenuPopover = React.forwardRef(
    (
      { left, top, className, onClose, options = [], activeKey, onSelect },
      ref
    ) => {
      return (
        <Popover ref={ref} className={className} style={{ left, top }} full>
          <Dropdown.Menu
            onSelect={(value) => {
              onSelect(value);
              onClose();
            }}
            activeKey={String(activeKey)}
            style={textStyle}
          >
            {options.map((option) => (
              <Dropdown.Item key={option.position} eventKey={option.name}>
                {renderMenuItem(option.name, option)}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Popover>
      );
    }
  );

  const renderMenuItem = (label, item) => {
    const handleMouseOver = (e) => {
      e.target.style.backgroundColor =
        formulaLabelSetting.dropdownStyles?.backgroundColor ||
        bgStyle.backgroundColor;
      e.target.style.color =
        formulaLabelSetting.dropdownStyles?.color || inputSettings?.color;
    };

    const handleMouseOut = (e) => {
      e.target.style.backgroundColor = bgStyle.backgroundColor;
      e.target.style.color = inputSettings?.color;
    };

    const menuItemStyle = {
      ...dropdownStyle,
      ...fontFamily,
    };

    return (
      <div
        key={item.value}
        style={menuItemStyle}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        {label}
      </div>
    );
  };
  const getFormatData = (item) => ({
    fontSize: item?.font_size ? `${item.font_size}px` : "16px",
    color: item?.color || "black",
    textDecoration: item?.underline == "1" ? "underline" : "none",
    fontWeight: item?.bold == "1" ? "bold" : "normal",
  });
//  useEffect(() => {
//   if (DosagesInputs && DosagesInputs.is_active == "1") {
//     setUsePresetDosageInstructions(true);
//   } else {
//     setUsePresetDosageInstructions(false);
//   }
//  }, [DosagesInputs]);

  return (
    <>
      {(DosagesInputs.is_active == "1" || OwnInstructions.is_active == "1") &&
        Object.values(dosageOptions).length != 0 && (
          <div className="FormulaBuilderDosagesSection">
            <h2 className="title mt-5">{display_name}</h2>
            <FormulaSectionTextBox
              data={formulaTextBox["DOSAGE_INSTRUCTIONS_GENERAL"]}
              dosageFormulaKey=""
            />
            <div className="row">
              <div className="col-8">
                {Object.values(dosageOptions).map((item, index) => {
                  const formatData = getFormatData(item);
                  // Handle DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_TEXT_BOX case
                  if (
                    item.key_value ==
                    "DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_TEXT_BOX"
                  ) {
                    return (
                      OwnInstructions?.is_active == "1" && (
                        <div
                          key={index}
                          className="col-12"
                          style={{ paddingBlock: "1rem" }}
                        >
                          {/* CheckBox for Own Instructions */}
                          <div className="checkBox">
                            {OwnInstructions.is_required == "0" && (
                              <input
                                type="checkbox"
                                value={allowOwnDosagesInstructions}
                                checked={allowOwnDosagesInstructions}
                                name="ownDosagesInstructions"
                                onChange={(e) =>
                                  setAllowOwnDosagesInstructions(
                                    e.target.checked
                                  )
                                }
                                className="custom-checkbox"
                              />
                            )}

                            <span style={formatData}>
                              {OwnInstructions.is_required == "0" && (
                                <>&nbsp;</>
                              )}
                              {OwnInstructions.display_name}
                              {OwnInstructions.is_required == "1" && (
                                <span style={{ color: "red" }}> *</span>
                              )}
                            </span>
                          </div>

                          {/* Textbox for Dosage Instructions */}
                          {allowOwnDosagesInstructions && (
                            <FormulaSectionTextBox
                              data={
                                formulaTextBox[
                                  "WRITE_YOUR_OWN_DOSAGE_INSTRUCTIONS_BELOW"
                                ]
                              }
                              dosageFormulaKey={dosageKey}
                            />
                          )}

                          {/* Textarea Input */}
                          {(allowOwnDosagesInstructions ||
                            OwnInstructions.is_required == "1") && (
                            <>
                              <textarea
                                value={decodeEntities(ownDosagesInstructions)}
                                onChange={(e) => {
                                  let textValue = e.target.value;
                                  let maxLength = parseInt(
                                    OwnInstructions.maxlength,
                                    10
                                  );

                                  if (
                                    maxLength &&
                                    maxLength != 0 &&
                                    textValue.length > maxLength
                                  ) {
                                    setOwnDosagesInstructions(
                                      textValue.slice(0, maxLength)
                                    );
                                  } else {
                                    setOwnDosagesInstructions(textValue);
                                  }
                                }}
                                style={{
                                  borderRadius: "6px",
                                  width: "83%",
                                  border: "1px solid black",
                                }}
                                rows={6}
                              ></textarea>

                              {/* Display character limit information */}
                              {OwnInstructions.maxlength &&
                                (() => {
                                  const maxLength = parseInt(
                                    OwnInstructions.maxlength,
                                    10
                                  );
                                  const textLength =
                                    ownDosagesInstructions?.length || 0;
                                  const charactersLeft = maxLength - textLength;

                                  return (
                                    <p>
                                      Max Characters: {maxLength} &nbsp;
                                      Characters Left:{" "}
                                      {charactersLeft >= 0 ? charactersLeft : 0}
                                    </p>
                                  );
                                })()}

                              {/* Error Message Display */}
                              {formError[
                                "DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_TEXT_BOX"
                              ] &&
                                ApplyFormValidation && (
                                  <ErrorMessage>
                                    {
                                      formError[
                                        "DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_TEXT_BOX"
                                      ]
                                    }
                                  </ErrorMessage>
                                )}
                            </>
                          )}
                        </div>
                      )
                    );
                  } else if (
                    item.key_value == "DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_INPUTS"
                  ) {
                    return (
                      DosagesInputs.is_active == "1" &&
                      dosagesData.length > 0 && (
                        <>
                          {/* <h5>{DosagesInputs.display_name}</h5> */}
                          {/* <FormulaSectionTextBox
                            data={formulaTextBox["DOSAGE_INPUT_BELOW"]}
                            dosageFormulaKey=""
                          /> */}
                          {/*  label  */}
                          <div
                            className="col-12"
                            style={{ paddingBlock: "1rem" }}
                          >
                            <div className="checkBox">
                              {DosagesInputs.is_required == 0
                                ? (
                                  (
                                    <input
                                      type="checkbox"
                                      value={usePresetDosageInstructions}
                                      checked={
                                        usePresetDosageInstructions == "0" ||
                                        usePresetDosageInstructions == 0
                                          ? false
                                          : true
                                      }
                                      name="usePresetDosageInstructions"
                                      onChange={(e) =>
                                        setUsePresetDosageInstructions(
                                          e.target.checked
                                        )
                                      }
                                      className="custom-checkbox"
                                    />
                                  ))
                                : null}
                              <span style={formatData}>
                                {DosagesInputs.is_required == 0 && (
                                  <>&nbsp;{DosagesInputs.display_name}</>
                                )}
                                {DosagesInputs.is_required == 1 && (
                                  <>
                                    {DosagesInputs.display_name}
                                    <span style={{ color: "red" }}> *</span>
                                  </>
                                )}
                              </span>
                              {formError[
                                "DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_INPUTS"
                              ] && ApplyFormValidation ? (
                                <>
                                  <ErrorMessage>
                                    {
                                      formError[
                                        "DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_INPUTS"
                                      ]["error"]
                                    }
                                  </ErrorMessage>
                                </>
                              ) : null}
                            </div>

                            {usePresetDosageInstructions  ==true && (
                              <FormulaSectionTextBox
                                data={formulaTextBox["DOSAGE_INPUT_BELOW"]}
                                dosageFormulaKey={dosageKey}
                              />
                            )}


                            {usePresetDosageInstructions  ==true &&
                              formulaDosagesType == "daily" &&
                              formulaDosagesSetting &&
                              formulaDosagesSetting.automatically_calculate_dosages ==
                                1 &&
                              ([
                                "ONE_INGREDIENT_TYPE_ONE_MEASUREMENT_TYPE",
                                "MULTIPLE_INGREDIENT_TYPES_ONE_MEASUREMENT_TYPE_FOR_ALL_INGREDIENTS",
                              ].includes(data.ingredient_selection_type) ||
                                ([
                                  "ONE_INGREDIENT_TYPE_MULTIPLE_MEASUREMENT_TYPES",
                                  "MULTIPLE_INGREDIENT_TYPES_MULTIPLE_MEASUREMENT_TYPES_PER_INGREDIENT",
                                ].includes(data.ingredient_selection_type) &&
                                  allow_multiple_measurements == 0)) && (
                                <>
                                  {formulaDosagesSetting.allow_to_change_automatically_calculate_dosages ==
                                    1 && (
                                      //console.log(allowUserToChangeAutomaticallyCalculateDosages, "allowUserToChangeAutomaticallyCalculateDosages"),
                                    <>
                                      <div className="checkBox">
                                        {/*<input
                                          type="checkbox"
                                          value={
                                            allowUserToChangeAutomaticallyCalculateDosages
                                          }
                                          checked={
                                            allowUserToChangeAutomaticallyCalculateDosages
                                          }
                                          name="automaticallyCalculateDosages"
                                          
                                          onChange={(e) => {
                                            console.log("Checkbox clicked:", e.target.checked);
                                            setAllowUserToChangeAutomaticallyCalculateDosages(e.target.checked);
                                          }}
                                          className="custom-checkbox"
                                        />*/}
                                        <input
                                          type="checkbox"
                                          value={automaticallyCalculateDosages }
                                          checked={automaticallyCalculateDosages}
                                          name="automaticallyCalculateDosages"
                                          onChange={(e) => {
                                            //console.log("automaticallyCalculateDosages Checkbox clicked:", e.target.checked);
                                            setAutomaticallyCalculateDosages(e.target.checked);
                                          }}
                                          className="custom-checkbox"
                                        />
                                        <span style={{ paddingLeft: "6px" }}>
                                          Automatically Calculate Dosages
                                        </span>
                                      </div>
                                    </>
                                  )}
                                </>
                              )}

                            {/*  Dosages Input  */}
                            <div className="row dosage-input">
                              {(usePresetDosageInstructions==true &&
                                DosagesInputs.length != 0) &&
                                Object.values(dosagesData).map((i, k) => {
                                  const defaultWidth = i?.default_width
                                    ? `${i.default_width}px`
                                    : "100px";
                                  if (i.drop_type === "placer") {
                                    return (
                                      <div
                                        className="col placer"
                                        value={i.values || ""}
                                        name={i.name}
                                        placeholder={i.name}
                                        title={i.name}
                                      >
                                        <p
                                          className="color"
                                          style={{
                                            marginTop: "2rem",
                                            width: defaultWidth,
                                          }}
                                        >
                                          {" "}
                                          {decodeEntities(i.name)}{" "}
                                        </p>
                                      </div>
                                    );
                                  }
                                  if (i.drop_type === "text") {
                                    var dosageValues = i.dosageValues
                                      ? JSON.parse(i.dosageValues)
                                      : [];
                                    return (
                                      // <>
                                      <div className="col ">
                                        <span
                                          className="text"
                                          style={{ color: "#b3b3b3" }}
                                        >
                                          {i.name}
                                        </span>
                                        {/* <pre>{JSON.stringify(i, null, 2)}</pre> */}
                                        <InputGroup
                                          style={{
                                            width: defaultWidth,
                                          }}
                                        >
                                          {i.drop_type == "number" ? (
                                            <Input
                                              className={"dosage-input-number"}
                                              placeholder={i.name}
                                              value={String(i.value)}
                                              onChange={(e, event) => {
                                                HandelDosagesSettings(e, i.id);
                                              }}
                                              onKeyPress={disableKeys}
                                              step={0.01}
                                            />
                                          ) : (
                                            <Input
                                              className={"dosage-input-number"}
                                              placeholder={i.name}
                                              value={String(i.value)}
                                              onChange={(e, event) => {
                                                HandelDosagesSettings(e, i.id);
                                              }}
                                              //onKeyPress={disableKeys}
                                              step={0.01}
                                            />
                                          )}

                                          {dosageValues &&
                                            dosageValues.length != 0 && (
                                              <InputGroup.Button
                                                style={{
                                                  backgroundColor: "unset",
                                                }}
                                              >
                                                <Whisper
                                                  className="rs-picker-toggle-caret"
                                                  placement="bottomStart"
                                                  trigger="click"
                                                  speaker={(props, ref) => (
                                                    <MenuPopover
                                                      ref={ref}
                                                      {...props}
                                                      options={dosageValues}
                                                      activeKey={String(
                                                        i.value
                                                      )}
                                                      onSelect={(value) =>
                                                        HandelDosagesSettings(
                                                          String(value),
                                                          i.id
                                                        )
                                                      }
                                                    />
                                                  )}
                                                >
                                                  <ArrowDownLineIcon
                                                  //style={{ fontSize: '25px' }}
                                                  />
                                                </Whisper>
                                              </InputGroup.Button>
                                            )}
                                        </InputGroup>
                                        {formError[
                                          "DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_INPUTS"
                                        ] && ApplyFormValidation ? (
                                          <>
                                            <ErrorMessage>
                                              {
                                                formError[
                                                  "DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_INPUTS"
                                                ][i.id]
                                              }
                                            </ErrorMessage>
                                          </>
                                        ) : null}
                                      </div>
                                      // </>
                                    );
                                  }
                                  if (i.drop_type === "number") {
                                    var dosageValues = i.dosageValues
                                      ? JSON.parse(i.dosageValues)
                                      : [];
                                    return (
                                      <div className="col ">
                                        <div className="form-text">
                                          <span
                                            className="text"
                                            style={{ color: "#b3b3b3" }}
                                          >
                                            {i.name}
                                          </span>
                                        </div>
                                        {/* <pre>{JSON.stringify(i, null, 2)}</pre> */}
                                        <InputGroup
                                          style={{ width: defaultWidth }}
                                        >
                                          <InputNumber
                                            className={"dosage-input-number"}
                                            placeholder={i.name}
                                            value={String(i.value)}
                                            onChange={(e, event) => {
                                              if (e != String(i.value)) {
                                                HandelDosagesSettings(e, i.id);
                                              }

                                              if (
                                                i.key_value ==
                                                "DOSAGE_INPUT_NUMBER_OF_DAYS"
                                              ) {
                                                //onNumberDaysChange(e)
                                              }
                                            }}
                                            onBlur={() => {
                                              if (
                                                i.key_value ==
                                                "DOSAGE_INPUT_NUMBER_OF_DAYS"
                                              ) {
                                                onNumberDaysChange(
                                                  String(i.value)
                                                );
                                              }
                                            }}
                                            onKeyPress={disableKeys}
                                            step={0.01}
                                          />
                                          {dosageValues &&
                                            dosageValues.length != 0 && (
                                              <InputGroup.Button
                                                style={{
                                                  backgroundColor: "unset",
                                                }}
                                              >
                                                <Whisper
                                                  className="rs-picker-toggle-caret"
                                                  placement="bottomStart"
                                                  trigger="click"
                                                  // speaker={<MenuPopover options={dosageValues} onSelect={HandelDosagesSettings} />}
                                                  speaker={(props, ref) => (
                                                    <MenuPopover
                                                      ref={ref}
                                                      {...props}
                                                      options={dosageValues}
                                                      activeKey={String(
                                                        i.value
                                                      )}
                                                      onSelect={(value) =>
                                                        HandelDosagesSettings(
                                                          String(value),
                                                          i.id
                                                        )
                                                      }
                                                    />
                                                  )}
                                                >
                                                  <ArrowDownLineIcon
                                                  //style={{ fontSize: '25px' }}
                                                  />
                                                </Whisper>
                                              </InputGroup.Button>
                                            )}
                                        </InputGroup>
                                        {formError[
                                          "DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_INPUTS"
                                        ] && ApplyFormValidation ? (
                                          <>
                                            <ErrorMessage>
                                              {
                                                formError[
                                                  "DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_INPUTS"
                                                ][i.id]
                                              }
                                            </ErrorMessage>
                                          </>
                                        ) : null}
                                      </div>
                                    );
                                  }
                                  if (i.drop_type === "selectBox") {
                                    var dosageValues = i.dosageValues
                                      ? JSON.parse(i.dosageValues)
                                      : [];

                                    var allowed_option = dosageValues.map(
                                      (item) => item.name
                                    );

                                    if (
                                      i.default_value &&
                                      i.default_value != null &&
                                      i.default_value != 0
                                    ) {
                                      allowed_option.push(i.default_value);
                                    }

                                    var option = [];
                                    var is_default = "";
                                    var allowed_option = [];
                                    var selected;
                                    // if its saved formula then set the default value
                                    if (
                                      i.default_value &&
                                      i.default_value != null &&
                                      i.default_value != 0
                                    ) {
                                      is_default = i.default_value;
                                    }
                                    // for 2nd senerio -> allowed multiple measurement types is OFF
                                    if (
                                      ingredient_selection_type ==
                                        "ONE_INGREDIENT_TYPE_MULTIPLE_MEASUREMENT_TYPES" &&
                                      allow_multiple_measurements == false
                                    ) {
                                      var allow_measurement_option =
                                        measurementOptions?.default_measurement;

                                      dosageValues.forEach((val) => {
                                        if (
                                          allow_measurement_option ==
                                          val.unit_id
                                        ) {
                                          option.push({
                                            value: val.name,
                                            label: val.name,
                                            is_default: val.set_default,
                                          });
                                          allowed_option.push(val.name);
                                          if (val.set_default == 1) {
                                            is_default = val.name;
                                          }
                                        }
                                      });

                                      selected =
                                        i.value != "" &&
                                        i.value != undefined &&
                                        i.value != null &&
                                        allowed_option.includes(i.value)
                                          ? String(i.value)
                                          : String(is_default);
                                    } else if (
                                      ingredient_selection_type ==
                                        "MULTIPLE_INGREDIENT_TYPES_MULTIPLE_MEASUREMENT_TYPES_PER_INGREDIENT" &&
                                      allow_multiple_measurements == false
                                    ) {
                                      var allow_measurement_option = [];
                                      Object.entries(measurementOptions).map(
                                        ([key, value1]) => {
                                          if (
                                            ingredient_selection.includes(
                                              value1.ingredient_id
                                            )
                                          ) {
                                            allow_measurement_option.push(
                                              value1.default_measurement
                                            );
                                          }
                                        }
                                      );
                                      dosageValues.forEach((val) => {
                                        if (
                                          allow_measurement_option.includes(
                                            val.unit_id
                                          )
                                        ) {
                                          option.push({
                                            value: val.name,
                                            label: val.name,
                                            is_default: val.set_default,
                                          });
                                          allowed_option.push(val.name);
                                          if (val.set_default == 1) {
                                            is_default = val.name;
                                          }
                                        }
                                      });

                                      selected =
                                        i.value != "" &&
                                        i.value != undefined &&
                                        i.value != null &&
                                        allowed_option.includes(i.value)
                                          ? String(i.value)
                                          : String(is_default);
                                    } else {
                                      dosageValues.forEach((val) => {
                                        option.push({
                                          value: val.name,
                                          label: val.name,
                                          is_default: val.set_default,
                                        });
                                        allowed_option.push(val.name);
                                        if (val.set_default == 1) {
                                          is_default = val.name;
                                        }
                                      });
                                      selected = allowed_option.includes(
                                        i?.value
                                      )
                                        ? String(i.value)
                                        : String(is_default);
                                      // console.log("selected", {
                                      //   selected,
                                      //   is_default,
                                      //   dosageValues,
                                      // });
                                    }
                                    const filteredOptions = option.filter(
                                      (item) =>
                                        item.label?.trim() && item.value?.trim()
                                    );
                                    if (
                                      filteredOptions.length == 0 ||
                                      selected == ""
                                    ) {
                                      var thisMeasurement = "";
                                      if (
                                        data.ingredient_selection_type ==
                                        "ONE_INGREDIENT_TYPE_ONE_MEASUREMENT_TYPE"
                                      ) {
                                        thisMeasurement =
                                          measurementOptions
                                            ?.this_measurement?.[0]
                                            ?.display_name || "";
                                        if (
                                          thisMeasurement != undefined &&
                                          thisMeasurement != ""
                                        ) {
                                          filteredOptions.push({
                                            value: thisMeasurement,
                                            label: thisMeasurement,
                                            is_default: 1,
                                          });
                                          selected = thisMeasurement;
                                          is_default = thisMeasurement;
                                        }
                                        //console.log("selectedDosagesUnit-all",thisMeasurement, {selected,thisMeasurement, option});
                                      }
                                    }

                                    if (
                                      (selected != i.value || !i.value) &&
                                      option.length != 0 &&
                                      selected != "" &&
                                      selected != null
                                    ) {
                                      // console.log("selectedDosagesUnit", selected, i.value,i,option,measurementOptions,);
                                      HandelDosagesSettings(selected, i.id); // re-render issue
                                    }
                                    return (
                                      <div className="col">
                                        <div className="form-text">
                                          <span
                                            className="text"
                                            style={{ color: "#b3b3b3" }}
                                          >
                                            {i.name}
                                          </span>
                                        </div>
                                        <SelectPicker
                                          classPrefix="dosage_measurement_type"
                                          placeholder={i.name}
                                          defaultValue={String(selected)}
                                          searchable={false}
                                          onChange={(e, event) => {
                                            HandelDosagesSettings(e, i.id);
                                          }}
                                          style={{ width: defaultWidth }}
                                          cleanable={false}
                                          value={String(selected)}
                                          data={filteredOptions}
                                          renderMenuItem={(label, item) =>
                                            renderMenuItem(label, item)
                                          }
                                        />
                                        {formError[
                                          "DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_INPUTS"
                                        ] && ApplyFormValidation ? (
                                          <>
                                            <ErrorMessage>
                                              {
                                                formError[
                                                  "DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_INPUTS"
                                                ][i.id]
                                              }
                                            </ErrorMessage>
                                          </>
                                        ) : null}
                                      </div>
                                    );
                                  }
                                })}

                              {/* {usePresetDosageInstructions && (
                              <span
                                style={{
                                  ...calculationFormatData,
                                  display: "block",
                                  marginTop: "1rem",
                                }}
                              >
                                <>{DosagesCalculation?.display_name}</>
                              </span>
                            )} */}
                              {/* <pre>{JSON.stringify((dosagesData), null, 2)}</pre>*/}
                            </div>
                          </div>

                          {/*  Dosages Input preview  */}
                          {/* {usePresetDosageInstructions &&
                            DosagesInputs &&
                            DosagesInputs.is_active == 1 &&
                            Object.values(dosagesData).length !== 0 && (
                              <div
                                className="col-12"
                                style={{
                                  marginTop: "1rem",
                                  lineHeight: "1.8",
                                  ...textStyle,
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: decodeEntities(dosagesText),
                                }}
                              ></div>
                            )} */}
                          {/* Dosages Calculation */}
                          {/* {usePresetDosageInstructions &&
                            DosagesCalculation &&
                            DosagesCalculation.is_active == 1 &&
                            Object.values(dosagesData).length != 0 &&
                            (() => {
                              return (
                                <div
                                  className="col-12"
                                  style={{
                                    //   marginTop: "1rem",
                                    marginBottom: "1.5rem",
                                    lineHeight: "1.8",
                                    ...textStyle,
                                  }}
                                >
                                  {dosagesQtyConText !== ""
                                    ? dosagesQtyConText
                                    : ""}
                                  <p>
                                    You require a total of {dosagesQty}{" "}
                                    {dosagesMeasurement}
                                  </p>
                                </div>
                              );
                            })()}  */}
                        </>
                      )
                    );
                  } else if (
                    item.key_value == "DOSAGE_OPTION_DOSAGE_CALCULATION" && usePresetDosageInstructions==true && DosagesInputs.is_active == "1" 
                  ) {
                    return (
                      // <>
                      <div className="col-12" style={{ paddingBottom: "1rem" }}>
                        {usePresetDosageInstructions  ==true && (
                          <span
                            style={{
                              ...formatData,
                              display: "block",
                              marginTop: "1rem",
                            }}
                          >
                            <>{DosagesCalculation?.display_name}</>
                          </span>
                        )}

                        {/*  Dosages Input preview  */}
                        {usePresetDosageInstructions ==true &&
                          DosagesInputs &&
                          DosagesInputs.is_active == 1 &&
                          Object.values(dosagesData).length !== 0 && (
                            <div
                              className="col-12"
                              style={{
                                marginTop: "1rem",
                                lineHeight: "1.8",
                                ...textStyle,
                              }}
                              dangerouslySetInnerHTML={{
                                __html: decodeEntities(dosagesText),
                              }}
                            ></div>
                          )}

                        {/* Dosages Calculation */}
                        {
                          DosagesCalculation &&
                          DosagesCalculation.is_active == 1 &&
                          Object.values(dosagesData).length != 0 &&
                          (() => {
                            return (
                              <div
                                className="col-12"
                                style={{
                                  //   marginTop: "1rem",
                                  // marginBottom: "1.5rem",
                                  lineHeight: "1.8",
                                  ...textStyle,
                                }}
                              >
                                {dosagesQtyConText !== ""
                                  ? dosagesQtyConText
                                  : ""}
                                <p>
                                  You require a total of {dosagesQty}{" "}
                                  {dosagesMeasurement} {usePresetDosageInstructions}
                                </p>
                              </div>
                            );
                          })()}
                      </div>
                      // </>
                    );
                  }
                  return null;
                })}
              </div>
              <div className="col-4">
                {/* <pre>{JSON.stringify(formValue, null, 2)}</pre> */}
                {/* <pre>{JSON.stringify([{dosagesDays,dosagesInputDays,numberDays}], null, 2)}</pre> */}
              </div>
            </div>
          </div>
        )}
    </>
  );
}
