
import React, { useState, useRef, useMemo, useCallback } from 'react';
import { Table, Input, InputNumber, Button } from 'rsuite';

// Memoized Table Component
const MyTableComponent = React.memo(({ increaseCount }) => {
  console.log("Table rendered"); // Debug log

  const [tableRowsData, setTableRowsData] = useState([
    { id: 1, col1: 'Herb 1', col2: 10, col3: 'Description 1', col4: 'Category 1', col5: 'Supplier 1', col6: 'Region 1', col7: 'Weight 1', col8: 'Stock 1', col9: 'Price 1', col10: 'Rating 1', col11: 'Sold 1', col12: 'Location 1', col13: 'Discount 1', col14: 'Discount Type 1', col15: 'Sale Status 1' },
    { id: 2, col1: 'Herb 2', col2: 15, col3: 'Description 2', col4: 'Category 2', col5: 'Supplier 2', col6: 'Region 2', col7: 'Weight 2', col8: 'Stock 2', col9: 'Price 2', col10: 'Rating 2', col11: 'Sold 2', col12: 'Location 2', col13: 'Discount 2', col14: 'Discount Type 2', col15: 'Sale Status 2' },
    { id: 3, col1: 'Herb 3', col2: 20, col3: 'Description 3', col4: 'Category 3', col5: 'Supplier 3', col6: 'Region 3', col7: 'Weight 3', col8: 'Stock 3', col9: 'Price 3', col10: 'Rating 3', col11: 'Sold 3', col12: 'Location 3', col13: 'Discount 3', col14: 'Discount Type 3', col15: 'Sale Status 3' },
  ]);

  const tableRef = useRef(null);
  const inputRefs = useRef({});

  const tableRows = useMemo(() => tableRowsData, [tableRowsData]);

  const handleChange = useCallback((value, key, field) => {
    setTableRowsData(prevRows =>
      prevRows.map(row => row.id === key ? { ...row, [field]: value } : row)
    );
  }, []);

  return (
    <div style={{ padding: '20px', overflowX: 'hidden' }} ref={tableRef}>
      <Table
        data={tableRows}
        bordered
        cellBordered
        rowKey="id"
        autoHeight
        virtualized
        headerHeight={60}
        rowHeight={65}
        hover
      >
        {['col1', 'col2', 'col3', 'col4', 'col5', 'col6', 'col7', 'col8', 'col9', 'col10', 'col11', 'col12', 'col13', 'col14', 'col15'].map((col, colIndex) => (
          <Table.Column key={colIndex} width={150} align="center">
            <Table.HeaderCell>{`Column ${colIndex + 1}`}</Table.HeaderCell>
            <Table.Cell>
              {(rowData, rowIndex) => (
                col === 'col2' || col === 'col9' || col === 'col11' ? (
                  <InputNumber
                    ref={(el) => inputRefs.current[`${rowIndex}-${colIndex}`] = el}
                    value={rowData[col]}
                    onChange={value => handleChange(value, rowData.id, col)}
                    style={{ width: '100%' }}
                    min={0}
                  />
                ) : (
                  <Input
                    ref={(el) => inputRefs.current[`${rowIndex}-${colIndex}`] = el}
                    value={rowData[col]}
                    onChange={value => handleChange(value, rowData.id, col)}
                    style={{ width: '100%' }}
                  />
                )
              )}
            </Table.Cell>
          </Table.Column>
        ))}
        <Table.Column width={150} align="center">
          <Table.HeaderCell>Actions</Table.HeaderCell>
          <Table.Cell>
            {(rowData) => (
              <>
                <Button appearance="primary" onClick={() => alert(`Action for ${rowData.col1}`)}>
                  Action
                </Button>
                <Button appearance="primary" onClick={increaseCount}>
                  Increase
                </Button>
              </>
            )}
          </Table.Cell>
        </Table.Column>
      </Table>
    </div>
  );
});

const ParentComponent = () => {
  const [count, setCount] = useState(0);

  // Wrap increaseCount in useCallback so it has a stable reference
  const increaseCount = useCallback(() => {
    setCount(prev => prev + 1);
  }, []);

  return (
    <div>
      <h3>Count: {count}</h3>
      <MyTableComponent increaseCount={increaseCount} />
    </div>
  );
};

export default ParentComponent;

