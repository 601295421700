import React from "react";
import {
  Drawer,
  ButtonToolbar,
  Button,
  Placeholder,
  SelectPicker,
  Form,
  Schema,
  Panel,
  Message,
  toaster,
  Input,
  InputGroup,
  Whisper,
  Tooltip,
} from "rsuite";
import InfoRoundIcon from "@rsuite/icons/InfoRound";
import he from "he"; // Import the HTML entity decoder library

const ErrorMessage = ({ children }) => (
  <>
    <Form.HelpText style={{ color: "red" }}>{children}</Form.HelpText>
    {/* <span style={{ color: 'red' }}>{children}</span> */}
  </>
);
// const decodeEntities = (html) => {
//   if(html == '') return '';
//   const doc = new DOMParser().parseFromString(he.decode(html), 'text/html')
//   return doc.documentElement.textContent
//   return he.decode(doc.documentElement.textContent)
// }

const decodeEntities = (html) => {
  if (typeof html !== "string") return html; // Prevent errors for non-string inputs

  let decoded = html;
  let previous;
  
  do {
    previous = decoded;
    decoded = he.decode(previous);
  } while (decoded !== previous); // Stop when no more decoding changes occur

  return decoded;
};

function InputForm({
  value,
  onChange = null, // on change option trigger
  error = "",
}) {
  var visibility = value.is_active;
  const ballStyle = {
    height: "38px",
    borderColor: value?.input_border_color,
    borderWidth: value?.input_border_size
      ? `${value.input_border_size}px`
      : "2px",
    backgroundColor: value?.input_bg_color,
    fontSize:
      value?.input_font_size != null ? `${value.input_font_size}px` : "16px",
    color: value?.field_font_color || "black",
  };

  const labelStyle = {
    fontSize:
      value?.field_font_size != null ? `${value.field_font_size}px` : "16px",
    whiteSpace: "nowrap",
    color: value?.field_font_color || "black",
    textDecoration: value?.field_underline == "1" ? "underline" : "none",
    fontWeight: value?.field_bold == "1" ? "bold" : "normal",
    backgroundColor: value?.field_highlight_color,
    display: "inline-block",
    paddingTop: value?.field_padding_top
      ? `${value.field_padding_top}px`
      : "0px",
    paddingRight: value?.field_padding_right
      ? `${value.field_padding_right}px`
      : "0px",
    paddingLeft: value?.field_padding_left
      ? `${value.field_padding_left}px`
      : "0px",
    paddingBottom: value?.field_padding_bottom
      ? `${value.field_padding_bottom}px`
      : "0px",
    border: "solid",
    borderRadius: "6px",
    borderColor: value?.field_border_color || "defaultColor",
    borderWidth: value?.field_border_size
      ? `${value.field_border_size}px`
      : "0px",
  };
  const margin = {
    marginTop: value?.field_margin_top ? `${value.field_margin_top}px` : "0px",
    marginRight: value?.field_margin_right
      ? `${value.field_margin_right}px`
      : "0px",
    marginLeft: value?.field_margin_left
      ? `${value.field_margin_left}px`
      : "0px",
    marginBottom: value?.field_margin_bottom
      ? `${value.field_margin_bottom}px`
      : "0px",
  };

  const handleSelectChange = (value, field) => {
    onChange(value, field);
  };
  const triggerName = value.triggerName;
  var requried = value.is_required;
  const is_required = requried == 1 ? "required" : "";

  if (value.is_active != 1) {
    return null; // or return undefined;
  }

  var width = value.width || value.width == "0" ? `${value.width}px` : "100%";
  return (
    <>
      <div className="row mb-3" style={margin}>
        <label
          htmlFor={value.field_name}
          className="col-sm-3 col-form-label"
          style={labelStyle}
        >
          <span>{value.display_name}</span>
          <span className="required">*</span>
        </label>
        <div className="col-sm-8">
          <Form.Group
            className={error ? "has-error" : ""}
            style={{ width: width }}
          >
            <InputGroup inside>
              {is_required ? (
                <Whisper trigger="focus" speaker={<Tooltip>Required</Tooltip>}>
                  <Input
                    style={ballStyle}
                    type="text"
                    name={value.key_value}
                    placeholder={value.field_placeholder}
                    value={decodeEntities(value.selectedValue || "")}
                    onChange={(e) => {
                      handleSelectChange(e, triggerName);
                    }}
                    required={is_required}
                    className="styled-input"
                  />
                </Whisper>
              ) : (
                <Input
                  style={ballStyle}
                  type="text"
                  name={value.key_value}
                  placeholder={value.field_placeholder}
                  value={decodeEntities(value.selectedValue || "")}
                  onChange={(e) => {
                    handleSelectChange(e, triggerName);
                  }}
                  required={is_required}
                  className="styled-input"
                />
              )}

              {/* <InputGroup.Addon>
                <Whisper placement="top" speaker={<Tooltip> Required</Tooltip>}>
                  <InfoRoundIcon />
                </Whisper>
              </InputGroup.Addon> */}
            </InputGroup>
            {error ? (
              <>
                <ErrorMessage>{error}</ErrorMessage>
              </>
            ) : null}
          </Form.Group>
        </div>
      </div>
      {/* Global styles for placeholder */}
      <style>
        {`
          .styled-input::placeholder {
            color: ${ballStyle.color};
            font-size: ${ballStyle.fontSize}
          }
        `}
      </style>
    </>
  );
}

export default InputForm;
