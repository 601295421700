import React, { useEffect, useState, useMemo, useCallback } from "react";
import { SelectPicker, Loader } from "rsuite";
import debounce from "lodash/debounce";

const FixedLoader = () => (
  <Loader
    content="Loading..."
    style={{
      display: "flex",
      justifyContent: "center",
      position: "absolute",
      bottom: "0",
      background: "#fff",
      width: "100%",
      padding: "6px 0",
    }}
  />
);

const IngredientSelect = React.memo(function IngredientSelect({
  formulaIngredients = [],
  thisNameType = "",
  rowData = {},
  column = "",
  onUpdate,
  is_duplicate,
  dropdownStyle,
  displayOptionStyle,
  handleFocus,
  colIndex,
}) {
  //console.log(formulaIngredients,"formulaIngredients3333")
  const [data, setData] = useState(formulaIngredients);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [value, setValue] = useState("");
  useEffect(() => {
    setLoading(false);
    setData(formulaIngredients);
    setValue(rowData.ingredient_id || "");
  }, [formulaIngredients, rowData.ingredient_id]);

  // Memoize customSort to avoid recalculating on every render
  const customSort = useCallback(
    (search = "") => {
      if (!formulaIngredients.length) return [];
      const searchLower = search.toLowerCase();
      const startsWithSearch = formulaIngredients
        .filter((item) => item.label.toLowerCase().startsWith(searchLower))
        .slice();
      const containsSearch = formulaIngredients
        .filter(
          (item) =>
            !item.label.toLowerCase().startsWith(searchLower) &&
            item.label.toLowerCase().includes(searchLower)
        )
        .slice();
      startsWithSearch.sort((a, b) => a.label.localeCompare(b.label));
      containsSearch.sort((a, b) => a.label.localeCompare(b.label));
      return [...startsWithSearch, ...containsSearch];
    },
    [formulaIngredients]
  );

  const handleSearch = (search) => {
    setSearchText(search);
    const filteredData = customSort(search);
    setData([]);
    setTimeout(() => setData(filteredData), 0);
  };

  const getItemStyle = (item) => {
    if (!item || !item.ing_id) {
      return {};
    }
    const matchedStyle = displayOptionStyle.find(
      (style) => style.ing_id == item.ing_id
    );
    return matchedStyle ? matchedStyle.style : {};
  };
  if (
    rowData?.selected_name_type == column &&
    rowData?.selected_name_key != ""
  ) {
    //|| column=='formula_dosage_column_all_names'
    return (
      <>
        <SelectPicker
          label={is_duplicate ? "Duplicate" : ""}
          size="xl"
          placement="auto"
          data={data} // Ensure data includes both the initial and filtered data
          onSearch={handleSearch}
          onChange={(value, item) => {
            //console.log('IngredientSelect',value,item)
            //onUpdate(value, rowData.id, column);
          }}
          onSelect={(value, item, event) => {
            console.log("IngredientSelect", value, rowData.id, column, item);
            onUpdate(value, rowData.id, column, item?.ing_name, item?.name_id);
          }}
          onClean={() => {
            onUpdate("", rowData.id, column, "", "");
          }}
          block
          value={String(rowData?.selected_name_key || "")}
          valueKey={"name_id"}
          renderValue={(value, item) => {
            const itemStyle = getItemStyle(item);
            var itemDisplayName = item?.display_name;

            return value === "" ? (
              itemDisplayName // Display placeholder without formatting
            ) : (
              <div
                style={{
                  ...dropdownStyle, // Apply dropdownStyle
                  ...itemStyle, // Apply item-specific matchedStyle
                }}
              >
                {itemDisplayName}
              </div> // Display formatted selected value
            );
          }}
          renderMenuItem={(label, item) => {
            const itemStyle = getItemStyle(item); // Apply matched style here as well
            return (
              <div
                style={{
                  ...dropdownStyle,
                  ...itemStyle,
                }}
              >
                {label}
              </div>
            );
          }}
          style={{ width: "100%" }}
          classPrefix={"ingredient-name-picker"}
          onFocus={() => handleFocus(colIndex)}
        />
        {loading && <FixedLoader />}
      </>
    );
  } else {
    return (
      <>
        <SelectPicker
          label={is_duplicate ? "Duplicate" : ""}
          size="xl"
          placement="auto"
          data={data} // Ensure data includes both the initial and filtered data
          onSearch={handleSearch}
          onChange={(value, item) => {
            //console.log('IngredientSelect',value,item)
            //onUpdate(value, rowData.id, column);
          }}
          onSelect={(value, item, event) => {
            console.log("IngredientSelect", value, rowData.id, column, item);
            onUpdate(value, rowData.id, column, item?.ing_name, item?.name_id);
          }}
          onFocus={() => handleFocus(colIndex)}
          onClean={() => {
            onUpdate("", rowData.id, column, "", "");
          }}
          block
          value={String(rowData.ingredient_id || "")}
          renderValue={(value, item) => {
            const itemStyle = getItemStyle(item);
            var itemDisplayName = item?.display_name;

            return value === "" ? (
              itemDisplayName // Display placeholder without formatting
            ) : (
              <div
                style={{
                  ...dropdownStyle, // Apply dropdownStyle
                  ...itemStyle, // Apply item-specific matchedStyle
                }}
              >
                {itemDisplayName}
              </div> // Display formatted selected value
            );
          }}
          renderMenuItem={(label, item) => {
            const itemStyle = getItemStyle(item); // Apply matched style here as well
            return (
              <div
                style={{
                  ...dropdownStyle,
                  ...itemStyle,
                }}
              >
                {label}
              </div>
            );
          }}
          style={{ width: "100%" }}
          classPrefix={"ingredient-name-picker"}
        />
        {loading && <FixedLoader />}
      </>
    );
  }
});

export default IngredientSelect;
