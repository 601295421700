import {
    Checkbox,
    SelectPicker,
  } from "rsuite";
  import he from "he";

export const RenderMeasurementOptions = ({ 
    measurementOptions = [],
    data,
    manageDefaultMeasurement,
    defaultMeasuremt,
    formulaDeatils,
    ingredient_selection,
    setdefaultMeasuremt,
    manageVIIngredients,
    manage_ingredient_selection,
    setOptionChange,
    // IngredientTableEmpty ,
    // setIsTableEmpty
}) => {
// console.log(measurementOptions,"formulaDeatils3222",ingredient_selection,"test",defaultMeasuremt,typeof defaultMeasuremt)
    if(data){
        const finalData = data?.extra_styling ? JSON.parse(data.extra_styling) : {};
        const value = finalData["FORMULA_BUILDER_LABEL_STYLING"];
        var inputSettings = {
            backgroundColor: value && value?.input_bg_color || "transparent",
            fontSize: value && value?.input_font_size ? `${value.input_font_size}px` : "12px",
            color: value && value?.input_font_color,
        };

        var border = {
            borderRadius: "6px",
            border: "solid",
            borderColor: value && value?.input_border_color || "defaultColor",
            borderWidth: value && value?.input_border_size
              ? `${value.input_border_size}px`
              : "1px",
        };
    }

    if (
        measurementOptions == "" ||
        measurementOptions.length == 0 ||
        measurementOptions == undefined
    ) {
        return (
            <label className="col-form-label">
                <i>No Ingredient Selection</i>
            </label>
        );
    }
    // console.log('ingredient_selection_type',data.ingredient_selection_type,data.allow_multiple_measurement_types_in_same_formula)
    if (
        data.ingredient_selection_type ===
        "ONE_INGREDIENT_TYPE_MULTIPLE_MEASUREMENT_TYPES"
      ) {
        const this_measurement = measurementOptions.this_measurement
          ? measurementOptions.this_measurement
          : [];
        return (
          <>
            <div
              className="col "
              style={{ paddingRight: "5px", display: "inline-flex" }}
            >
              <span
                className="text-right"
                style={{ paddingRight: "6px", paddingTop: "7px" }}
              >
                {measurementOptions.ingredient_name}
              </span>
              <SelectPicker
                size="xl"
                data={this_measurement}
                placement="auto"
                onChange={(value) => {
                  manageDefaultMeasurement(
                    value,
                    measurementOptions.ingredient_id
                  );
                }}
                value={String(measurementOptions.default_measurement)}
                labelKey={"display_name"}
                valueKey={"id"}
                cleanable={false}
                searchable={false}
                style={{ float: "right", minWidth: "100px" }}
                renderValue={(value, item) => {
                  return item ? item.display_name : "";
                }}
              />
            </div>
          </>
        );
      } else if (
        data.ingredient_selection_type ===
        "MULTIPLE_INGREDIENT_TYPES_MULTIPLE_MEASUREMENT_TYPES_PER_INGREDIENT"
      ) {
        if (data.allow_multiple_measurement_types_in_same_formula != "1") {
          const allUnits = [];
          const allUnitIds = [];
          // Collect all unique measurement options
          Object.values(measurementOptions || {}).forEach((measurement) => {
            measurement?.this_measurement?.forEach((val) => {
              if (val?.display_name) {
                allUnits.push({ label: val.display_name, id: val.id });
                allUnitIds.push(val.id);
              }
            });
          });
          // Remove duplicate values based on 'id'
          const uniqueUnits = [...new Map(allUnits.map((item) => [item.id, item])).values()];
          if (allUnitIds.includes(defaultMeasuremt) == false) {
            if (formulaDeatils?.seleted_ingredient_type) {
              // json decode issue fixed by Virat
              //const temp = formulaDeatils?.seleted_ingredient_type ?JSON.parse(formulaDeatils.seleted_ingredient_type):[];
              let selected_ingredint_type = formulaDeatils.selected_ingredint_type;

              // If it's a string with &quot;, replace it with actual quotes
              if (typeof selected_ingredint_type === "string" && selected_ingredint_type.includes("&quot;")) {
                  selected_ingredint_type = selected_ingredint_type.replace(/&quot;/g, '"');
              }

              // Parse if it's a JSON string
              if (typeof selected_ingredint_type === "string") {
                  selected_ingredint_type = JSON.parse(selected_ingredint_type);
              }
              // let selected_ingredint_type = [] ;
              // try{
              //   if(formulaDeatils?.seleted_ingredient_type)
              //     selected_ingredint_type =  JSON.parse(formulaDeatils.seleted_ingredient_type);
              // }catch(e){
              //   if(formulaDeatils?.seleted_ingredient_type){
              //     const decodedJsonString = formulaDeatils?.seleted_ingredient_type && he.decode(formulaDeatils.seleted_ingredient_type);
              //     selected_ingredint_type = formulaDeatils?.seleted_ingredient_type && JSON.parse(decodedJsonString);
              //   }
                
              // }
              // console.log(selected_ingredint_type,"selected_ingredint_type3")
              // Extract ingredient IDs
              const temp = Array.isArray(selected_ingredint_type)
                  ? selected_ingredint_type.map((ittete) => ittete.ingredient_id)
                  : [];
  
              if (temp.length != 0) {
                var save_allowed_ingredients = [];
                const selectedIngredientMeasuremnt = {};
                temp.forEach((value1) => {
                  save_allowed_ingredients.push(value1.ingredient_id);
                  selectedIngredientMeasuremnt[value1.ingredient_id] =
                    value1.default_measurement;
                  //manage_ingredient_selection(true, value1.ingredient_id);
                  //manageDefaultMeasurement(value1.default_measurement, value1.ingredient_id);
                });
  
                if (save_allowed_ingredients.length != 0) {
                  if (
                    JSON.stringify(save_allowed_ingredients) !=
                    JSON.stringify(ingredient_selection)
                  ) {
                    setdefaultMeasuremt(save_allowed_ingredients);
                  }
                }
              }
            } else {
              setdefaultMeasuremt(allUnitIds[0]);
            }
          }

          // console.log(uniqueUnits,"uniqueUnits",defaultMeasuremt)
  
          //let thisDefaultMeasurement = defaultMeasuremt;//formulaDeatils?.measurement ;
  
          // Ensure a valid default measurement
          // if (defaultMeasuremt==''  && uniqueUnits.length > 0) {
          //   setdefaultMeasuremt(uniqueUnits[0]?.id);
  
          // }else if (thisDefaultMeasurement != defaultMeasuremt) {
          //   setdefaultMeasuremt(thisDefaultMeasurement);
          // }
  
          return (
            <>
              {/* Dropdown Box */}
              <div className="col-12">
                <SelectPicker
                  className="col-3"
                  size="xl"
                  data={uniqueUnits}
                  placement="auto"
                  onChange={(value) => {
                    
                    manageVIIngredients(value,true);
                    setOptionChange(true);
                    localStorage.setItem("stopMatchedData", true);

                    // IngredientTableEmpty(true)
                    // setIsTableEmpty(true)
                }
                  }
                  value={defaultMeasuremt}
                  labelKey="label"
                  valueKey="id"
                  cleanable={false}
                  searchable={false}
                  style={{ ...inputSettings, ...border, width: "96px" }}
                /> 
                <div className="clearfix"></div>
  
                {/* Checkbox List */}
                <div className="ingredients-checkbox">
                  <div className="row">
                    {/* {
                      console.log(measurementOptions,"measurementOptions123456",measurementOptions.length)
                    } */}
                    {
                    Object.values(measurementOptions || {})
                      .filter((measurement) => {
                        const thisMeasurement =
                          typeof measurement?.measurement == "string"
                            ? measurement.measurement.split(",").map((num) => num.trim())
                            : [];
                            // console.log(thisMeasurement,"measurementOptions123456")
                        return thisMeasurement.includes(String(defaultMeasuremt));
                      })
                      .map((measurement) => {
                        return (
                          <span key={measurement.ingredient_id} className="col text-left">
                            {
                              // console.log("measurementOptions123456",measurement,ingredient_selection.includes(measurement?.ingredient_id),measurement?.ingredient_id,ingredient_selection)
                            }
                            <Checkbox
                              onChange={(e, checked) => manage_ingredient_selection(checked, measurement?.ingredient_id)}
                              checked={ingredient_selection.includes(measurement?.ingredient_id)}
                            //   checked={JSON.parse(formulaDeatils.seleted_ingredient_type).some(item => item.ingredient_id === measurement.ingredient_id)}

                              style={{ marginLeft: "-8px" }}
                            >{measurement.ingredient_name}
                              {/* - {String(measurement.default_measurement)} */}
  
                              {/* <pre>{JSON.stringify(ingredient_selection, null, 2)}</pre> */}
                            </Checkbox>
                          </span>
                        )
                      })}
                  </div>
                </div>
              </div>
            </>
          );
  
  
  
        } else {
          return Object.entries(measurementOptions).map(([key, value1]) => {
            const this_measurement = value1.this_measurement;
            return (
              <div className="row" key={key}>
                <span className="col-10 text-left" style={{ width: "85%" }}>
                  <Checkbox
                    onChange={(e, checked) => {
                      manage_ingredient_selection(checked, value1.ingredient_id);
                    }}
                    defaultChecked
                    value="1"
                    style={{ marginLeft: "-8px" }}
                    checked={ingredient_selection.includes(value1.ingredient_id)}
                  // className="custom-checkbox"
                  >
                    {value1.ingredient_name}
                  </Checkbox>
                </span>
                <div
                  className="col-10 pull-left"
                  style={{ paddingRight: "5px", marginLeft: "1px" }} //width: '20%'
                >
                  <SelectPicker
                    size="xl"
                    data={this_measurement}
                    placement="auto"
                    onChange={(value) => {
                      manageDefaultMeasurement(value, value1.ingredient_id);
                    }}
                    value={String(value1.default_measurement)}
                    labelKey={"display_name"}
                    valueKey={"id"}
                    cleanable={false}
                    searchable={false}
                  // renderValue={(value, item) => {
                  //   return item ? item.display_name : ''
                  // }}
                  />
                </div>
              </div>
            );
          });
        }
  
      } else if (
        data.ingredient_selection_type ===
        "MULTIPLE_INGREDIENT_TYPES_ONE_MEASUREMENT_TYPE_PER_INGREDIENT"
      ) {
        return Object.entries(measurementOptions).map(([key, value1]) => {
          const this_measurement = value1.this_measurement;
          return (
            <div className="row">
              <span className="col-5 text-right">
                <Checkbox
                  onChange={async (e, checked) => {
                    // const checked = e.target.checked
                    await manage_ingredient_selection(
                      checked,
                      value1.ingredient_id
                    );
                  }}
                  defaultChecked
                  //value='1'
                  style={{ marginLeft: "-8px" }}
                >
                  {value1.ingredient_name} ({this_measurement?.[0]?.display_name || ""})
                </Checkbox>
              </span>
            </div>
          );
        });
      } else if (
        data.ingredient_selection_type ===
        "ONE_INGREDIENT_TYPE_ONE_MEASUREMENT_TYPE"
      ) {
        return measurementOptions.this_measurement.map((value1, k) => (
          <div className=" col-form-label">
            <span>
              {" "}
              {measurementOptions.ingredient_name} ({value1.display_name})
            </span>
          </div>
        ));
      } else if (
        data.ingredient_selection_type ==
        "MULTIPLE_INGREDIENT_TYPES_ONE_MEASUREMENT_TYPE_FOR_ALL_INGREDIENTS"
      ) {
        return Object.entries(measurementOptions).map(([key, value1]) => {
          const this_measurement = value1.this_measurement;
          //if (key == 0) {
          return (
            <div className="row">
              <span
                className="col-4 text-left"
                style={{ width: "100%", marginRight: "0.9rem" }}
              >
                {value1.ingredient_name} ({this_measurement[0].display_name})
              </span>
              {/* <div
                className="col-4 pull-right"
                style={{ paddingRight: "5px", width: "67%" }}
              >
                {this_measurement[0].display_name} ({this_measurement[0].name})
              </div> */}
            </div>
          );
          //}
        });
      }
}
