import React, { useEffect, useLayoutEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { NoInternetPopup } from "./helpers/system";

import "./assets/app.css";
import storage from "./storage";
import { Provider } from "react-redux";

//import Header from './components/common/Header/Header'
//import Footer from './components/common/Footer/Footer'
import FormulaBuilder from "./components/FormulaBuilder/FormulaBuilder";
import Test from "./components/Test/Test";
import Test2 from "./components/Test/Test2";


import Cookies from "js-cookie";

const App = () => {
  // useEffect(() => {
  //   localStorage.clear();
  //   sessionStorage.clear();
  //   Object.keys(Cookies.get()).forEach((cookie) => Cookies.remove(cookie));
  // }, []); // Runs only once
  useLayoutEffect(() => {
    // Remove only app-specific keys from local storage and session storage
    // const appKeys = ['app_token', 'user_data']; // Add your specific keys here
    // appKeys.forEach((key) => {
    //   localStorage.removeItem(key);
    //   sessionStorage.removeItem(key);
    // });
    // // Remove only app-specific cookies
    // Object.keys(Cookies.get()).forEach((cookie) => {
    //   if (cookie.startsWith('app_')) {
    //     Cookies.remove(cookie);
    //   }
    // });
  }, []); // Runs only once

  return (
    <>
      <BrowserRouter>
        <div className="App">
          <NoInternetPopup />
          {/* <Header /> */}
          <Provider store={storage}>
            <Routes>
              <Route path="/:id" element={<FormulaBuilder />} />
              <Route path="/test/:fId/:formulaT" element={<Test />} />
              <Route path="/test2" element={<Test2 />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Provider>
          {/* <Footer /> */}
        </div>
      </BrowserRouter>
    </>
  );
};

function NotFound() {
  return (
    <h1 style={{ textAlign: "center", marginTop: "6rem" }}>404 Not Found</h1>
  );
}

export default App;
