import React, { useEffect, useState } from "react";
import {
  Tooltip,
  Whisper,
  Panel,
  Placeholder,
  Row,
  Col,
  Divider,
  Form,
} from "rsuite";
import FormulaSectionTextBox from "../helpers/TextBox";
function truncateText(text, maxLength) {
  if (text.length <= maxLength) {
    return text;
  }
  return text.slice(0, maxLength) + "..."; // Add ellipsis (...) to indicate truncated text
}
const capitalizeFirstLetter = (inputString) => {
  return inputString.charAt(0).toUpperCase() + inputString.slice(1);
};
export default function FormulaBuilderServicesSection({
  formulaBuilderId,
  data,
  currency,
  onServicesSectionChange,
  formulaDeatils,
  display_name = "",
  ApplyFormValidation,
  thisFormData,
  selectedAddOnServices,
  price,
  formulaTextBox,
}) {
  const [serviceArr, setServiceArr] = useState([]);
  const [addOnServices, setaddOnServices] = useState([]);
  const [selected, setSelected] = useState([]);

  const [formError, setFormError] = React.useState({});
  const [formValue, setFormValue] = React.useState({});
  var requirements = [
    {
      column: "addon_services",
      name: "Add On Services",
      is_required: 0,
      key: "addon_services",
      allow_zero: false,
    },
  ];
  useEffect(() => {
    var servicesData = data.addOnServices ? JSON.parse(data.addOnServices) : [];
    // Cateory Grouping
    // Flatten the array and convert it to an array of objects
    const flatData = servicesData.flatMap((item) => {
      const key = Object.keys(item)[0];
      return [{ ...item[key], category: key }];
    });

    // Group the data by the 'category' field
    const groupedData = flatData.reduce((acc, item) => {
      const { category, ...rest } = item;
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(rest);
      return acc;
    }, {});
    // Update the state with the grouped data
    setServiceArr(groupedData);
    // Simple Array
    //setServiceArr(servicesData)
    // ####################################
    //setTimeout(() => {
    if (formulaDeatils && formulaDeatils.id) {
      if (formulaDeatils.add_on_services_details) {
        const selectedArr = formulaDeatils.add_on_services_details
          .split(",")
          .map(String);

        setSelected(selectedArr);

        servicesData?.map((value, k) => {
          Object.entries(value)?.map(([key, value1]) => {
            if (selectedArr.includes(value1.id)) {
              var checked = true;
              setaddOnServices((prev) =>
                checked
                  ? [...prev, value1]
                  : prev.filter((val) => val != value1)
              );
            }
          });
        });
      }
    }
    //}, 500)
  }, [formulaBuilderId, formulaDeatils]);

  const handleChange = (event, value1) => {
    const { checked, value } = event.currentTarget;

    setaddOnServices((prev) =>
      checked ? [...prev, value1] : prev.filter((val) => val !== value1)
    );

    setSelected((prev) =>
      checked ? [...prev, value1.id] : prev.filter((val) => val !== value1.id)
    );
    formValidation();
  };

  useEffect(() => {
    // Remove blank values and duplicates
    const newArray = [...new Set(selected.filter((value) => value))];
    onServicesSectionChange(newArray);
    formValidation();
  }, [addOnServices, selected, price]);


  //FORMULAS_ADD_ON_SERVICES
  // useEffect(() => {
  //   formValidation()
  // }, [addOnServices])

  useEffect(() => {
    if (ApplyFormValidation == true) {
      //formValidation()
    }
  }, [ApplyFormValidation]);

  const formValidation = () => {
    var servicesDataArr = data.addOnServices
      ? JSON.parse(data.addOnServices)
      : [];
    const servicesData = servicesDataArr.flatMap((item) => {
      const key = Object.keys(item)[0];
      return [{ ...item[key], category: key }];
    });
    var allow_services = [];
    servicesData?.map((i, k) => {
      if (selected.includes(i.id)) {
        const addon_price = Number(i?.price);
        const addon_tax = Number(i?.tax);
        var tax = (Number(price) * addon_tax) / 100;
        //allow_services.push({id:i.id,name:i.name,price:addon_price,tax:tax})
        allow_services.push({
          ...i,
          price: addon_price,
          tax_price: tax,
          ingredient_price: price,
        });
      }
    });
    selectedAddOnServices(allow_services);
    formValue["addons"] = allow_services;
    const temp = {
      ...requirements,
      value: formValue["addons"],
      error: formError["addons"],
    };
    thisFormData(temp, "FORMULAS_ADD_ON_SERVICES");
  };

  // Define variables outside the `if` block for broader scope
  let serviceTitle = {};
  let serviceSubTitle = {};
  let serviceDescription = {};
  let servicePriceDisplay = {};
  let serviceGeneralDisplay = {};

  if (data && data != null) {
    const formatData = data?.addon_services_display_options
      ? JSON.parse(data.addon_services_display_options)
      : {};

    serviceTitle = {
      fontSize: formatData?.title_font_size
        ? `${formatData.title_font_size}px`
        : "12px",
      color: formatData?.title_color || "black",
      textDecoration: formatData?.title_underline == "1" ? "underline" : "none",
      fontWeight: formatData?.title_bold == "1" ? "bold" : "normal",
    };

    serviceSubTitle = {
      fontSize: formatData?.subtitle_font_size
        ? `${formatData.subtitle_font_size}px`
        : "12px",
      color: formatData?.subtitle_color || "black",
      textDecoration:
        formatData?.subtitle_underline == "1" ? "underline" : "none",
      fontWeight: formatData?.subtitle_bold == "1" ? "bold" : "normal",
    };

    serviceDescription = {
      fontSize: formatData?.desc_font_size
        ? `${formatData.desc_font_size}px`
        : "12px",
      color: formatData?.desc_color || "black",
      textDecoration: formatData?.desc_underline == "1" ? "underline" : "none",
      fontWeight: formatData?.desc_bold == "1" ? "bold" : "normal",
    };

    servicePriceDisplay = {
      fontSize: formatData?.price_font_size
        ? `${formatData.price_font_size}px`
        : "12px",
      color: formatData?.price_color || "black",
      textDecoration: formatData?.price_underline == "1" ? "underline" : "none",
      fontWeight: formatData?.price_bold == "1" ? "bold" : "normal",
    };

    serviceGeneralDisplay = {
      border: "solid",
      borderColor: formatData?.general_border_color || "defaultColor",
      borderWidth: formatData?.general_border
        ? `${formatData.general_border}px`
        : "1px",
      backgroundColor: formatData?.general_bg_color || "transparent",
    };
  }

  // Use the variables as needed below this block

  if (serviceArr && Object.values(serviceArr).some((services) => services.length > 0)) {
    return (
      <>
        <div className="FormulaBuilderServicesSection">
          {<h2 className="title mt-5">{display_name}</h2>}
          <FormulaSectionTextBox
            data={formulaTextBox["ADD_ON_SERVICES_BELOW"]}
            dosageFormulaKey=""
          />
          {/* <pre>{JSON.stringify([selected, typeof selected], null, 2)}</pre> */}
          <div className="row">
            <div className="col-8">
              <div className="row">
                {Object.entries(serviceArr).map(([category, services]) => (
                  <div className="col-12" key={category}>
                    <h4 className="title" style={{ marginLeft: "5px" }}>
                      <Whisper
                        speaker={
                          <Tooltip>
                            {category && category !== 0 ? category : ""}
                          </Tooltip>
                        }
                      >
                        <u style={serviceSubTitle}>
                          {category && category != 0
                            ? truncateText(capitalizeFirstLetter(category), 110)
                            : ""}
                        </u>
                      </Whisper>
                    </h4>
                    <Row style={{ marginBottom: "1rem" }}>
                      {services
                        .sort(
                          (a, b) =>
                            parseInt(a.position, 10) - parseInt(b.position, 10)
                        )
                        .map((value1) => {
                          const headerText = (
                            <div>
                              <strong style={serviceTitle}>
                                {value1?.name}
                              </strong>
                              <span className="">
                                <input
                                  type="checkbox"
                                  value={value1.id}
                                  checked={selected.includes(value1.id)}
                                  name={value1.name}
                                  onChange={(e) => handleChange(e, value1)}
                                  style={{ float: "right" }}
                                  className="custom-checkbox"
                                />
                              </span>
                            </div>
                          );

                          const truncatedDesText = truncateText(
                            value1.description,
                            125
                          );
                          const price = parseFloat(value1?.price) || 0;
                          const tax = parseFloat(value1?.tax) || 0;

                          // value1?.description && value1.description.length > 80
                          //   ? value1.description.substring(0, 90) + '...'
                          //   : value1.description

                          return (
                            <Col md={8} sm={8} key={value1.id}>
                              <Panel
                                bordered
                                header={headerText}
                                style={{
                                  marginTop: "5px",
                                  marginBottom: "5px",
                                  height: "9.5rem",
                                  ...serviceGeneralDisplay,
                                }}
                              >
                                <small
                                  className="text-right"
                                  style={servicePriceDisplay}
                                >
                                  {currency}
                                  {/* {price.toPrecision(4)} + {tax.toPrecision(2)} */}
                                  {price.toFixed(2)} + {tax.toFixed(2)}% of
                                  Formula
                                </small>
                                <Divider style={{ margin: "0px" }} />
                                <Whisper
                                  followCursor
                                  speaker={
                                    <Tooltip>{value1.description}</Tooltip>
                                  }
                                >
                                  <small style={serviceDescription}>
                                    {truncatedDesText}
                                  </small>
                                </Whisper>
                              </Panel>
                            </Col>
                          );
                        })}
                    </Row>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-4"></div>
          </div>
          <Row className=""></Row>
        </div>
      </>
    );
  }
}
