import React, { useEffect, useState } from "react";
import { Drawer, ButtonToolbar, Button, Placeholder } from "rsuite";

import "rsuite/dist/rsuite.min.css";
import PlusIcon from "@rsuite/icons/Plus";
import { APIConfig } from "../../../apiConfig";
const { PATIENT_ADD_FORM_URL } = APIConfig;

function PatientAddBtn({ value, BASEURL }) {
  var value = value[1];
  const [popup, setPopup] = React.useState(false);
  if (!value) return null
  const ballStyle = {
    width: "30%",
    padding: "5px",
    fontSize: value?.field_font_size ? `${value.field_font_size}px` : "12px",
    color: value?.field_font_color || "#fff",
    textDecoration: value?.field_underline == "1" ? "underline" : "none",
    fontWeight: value?.field_bold == "1" ? "bold" : "normal",
    backgroundColor: value?.field_highlight_color,
    borderColor: value?.input_border_color,
    borderWidth: value?.input_border_size
      ? `${value.input_border_size}px`
      : "1px",
    marginTop: value?.field_margin_top ? `${value.field_margin_top}px` : "0px",
    marginRight: value?.field_margin_right
      ? `${value.field_margin_right}px`
      : "0px",
    marginLeft: value?.field_margin_left
      ? `${value.field_margin_left}px`
      : "0px",
    marginBottom: value?.field_margin_bottom
      ? `${value.field_margin_bottom}px`
      : "0px",
    position: value?.position,
    // visibility: value.is_active ? "visible" : "hidden",
  };

  if (value.is_active != 1) {
    return "";
  }
  const Hidden = { display: value.is_active };
  return (
    <>
      {/* <pre>{JSON.stringify(value, null, 2)}</pre> */}
      <a
        className="btn btn-secondary"
        style={ballStyle}
        href={BASEURL + '?page=patient&section=add&'}
        target="_blank"
      >
        <PlusIcon style={{ fontSize: "12px" }} /> {value.display_name}
      </a>

      {/* <Drawer open={popup} onClose={() => setPopup(false)}>
        <Drawer.Header>
          <Drawer.Title>Add Patient</Drawer.Title>
          <Drawer.Actions>
            <Button onClick={() => setPopup(false)}>Close</Button>
          </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body>
          {PATIENT_ADD_FORM_URL && (
            <iframe
              className='Add_PATIENT_IFRAME'
              title='Patient Add Form'
              src={PATIENT_ADD_FORM_URL}
              width='auto'
              height='auto'
              frameBorder='0'
              allowFullScreen
            />
          )}
        </Drawer.Body>
      </Drawer> */}
    </>
  );
}

export default PatientAddBtn;
