import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import FormulaBuilder from "../FormulaBuilder/FormulaBuilder";

const Test = () => {
  const { fId, formulaT } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  //const IFRAMEURL = 'https://herbdispenser.herbmanager.com/?page=details&'; no need to pass this as a prop

  const prac = searchParams.get("PRACID") || "100";
  const is_order = searchParams.get("IS_ORDER") === "true";

  const formula = fId; // formula Builder id
  const id = formulaT; // which index save this formula in product table

  const server = 'herbdev1'; // Use any one of them [localhost,herbdev1,herbmanager]

  let APIURL, DISPENSARY_TOKEN, BASEURL, PANEL; // Declare variables outside the blocks

  if (server == 'localhost') {
    APIURL = 'http://localhost/practice-api/formula-api';
    DISPENSARY_TOKEN = '46e3c95a8b75e2503c';
    BASEURL = 'http://localhost/multidispensary/user';
  } else if (server == 'herbdev1') {
    APIURL = 'https://practice-api.webgarh.net/formula-api';
    DISPENSARY_TOKEN = '46e3c95a8b75e2503c';
    BASEURL = 'https://herbdispenser.herbdev1.com/';
  } else if (server == 'herbmanager') {
    APIURL = 'https://api.herbmanager.com';
    DISPENSARY_TOKEN = '4ea1ad7cd4cfa86595';
    BASEURL = 'https://herbdispenser.herbmanager.com';
  }

  PANEL = searchParams.get("PANEL") || ""; // blank or admin 

  return (
    <>
      <div className="row" style={{ marginBottom: "20px" }}>
        <pre className="col-2">{JSON.stringify([{ id, prac, formula, is_order }], null, 2)}</pre>
        <pre className="col-6">{JSON.stringify([{APIURL, DISPENSARY_TOKEN, BASEURL, PANEL}], null, 2)}</pre>
      </div>
      {/* Correctly passing props */}
      <FormulaBuilder {...{ id, prac, formula, is_order, DISPENSARY_TOKEN, APIURL, BASEURL, PANEL }} />
    </>
  );
};

export default Test;
