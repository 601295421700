import React, { useEffect, useState } from "react";
import styled from "styled-components";// Import the close icon from react-icons

const PopupContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const PopupContent = styled.div`
  background: white;
  padding: 19px;
  border-radius: 8px;
  text-align: center;
  width: 100%;
  max-width: 700px;
  position: relative; /* To position the close icon */
`;

const AdminMessage = styled.p`
  font-size: 16px;
  color: black;
  margin-top: 20px;
  padding: 10px;
  width: fit-content;
`;
const Button = styled.button`
padding: 10px 20px;
border: 1px solid #ccc;
background-color: white;
font-size: 16px;
cursor: pointer;

&:hover {
  background-color: #f0f0f0;
}
`;
export default function BundlePopup({ message, onClose }) {

  const [popupVisible, setPopupVisible] = useState(false);

  useEffect(() => {
    if (message) {
      setPopupVisible(true);
    }
  }, [message]);

  if (!popupVisible) return null;

  return (
    <PopupContainer>
      <PopupContent>
        <AdminMessage>{message}</AdminMessage>
        <Button onClick={onClose}>Close</Button>
      </PopupContent>
    </PopupContainer>
  );
}
