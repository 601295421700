import { InputNumber } from "rsuite";
import { useCallback, useMemo, useState } from "react";
import React from "react";
import { useEffect } from "react";

// Function to determine decimal length
const decimalLength = (value) => {
  if (typeof value !== "string" && typeof value !== "number") return 0;
  const parts = value.toString().split(".");
  return parts.length > 1 ? parts[1].length : 0;
};

const IngredientQtyInput = React.memo(
  ({
    thisValue,
    rowData,
    column,
    rowIndex,
    colIndex,
    inputRefs,
    handleIngredientQty,
    handleTabKey,
    handleFocus,
    conversion_type,
    dosagesDays,
    fontStyle,
    decimalPlace,
    dataKey,
    data,
    setData
  }) => {
    // console.log("inputFieldRender", decimalPlace)

    const [inputValue, setInputValue] = useState(thisValue); 

    useEffect(() => {
      // Update inputValue when thisValue changes
      setInputValue(thisValue); 
    }, [thisValue]);

    const handleChangeM = useCallback((value,event) => {
      if (parseInt(Number(value)).toString().length >= 6) {
        return;
      }
      if (inputValue?.[dataKey]?.[rowData.id] === undefined && !value.includes(".") && value == parseInt(rowData[column.id])) {
        return;
      }
       // Get cursor position before updating state
      const inputElement = event?.target;
      const cursorPosition = inputElement?.selectionStart ?? 0;

      setInputValue((prevData) => {
        return {
          ...prevData,
          [dataKey]: {
            [rowData.id]: value
          }
        }
      })

      // Restore cursor position after state updates
      setTimeout(() => {
        inputElement?.setSelectionRange(cursorPosition, cursorPosition);
      }, 0);

    }, [inputValue]);  // ✅ Only re-creates when inputValue changes



    const handleBlurM = useCallback(() => {
      if (inputValue?.[dataKey]?.[rowData.id] === undefined || inputValue?.[dataKey]?.[rowData.id] == 0) {
        return;
      }
      const ThisIndex = data.findIndex(
        (ingredient) =>
          ingredient.id == rowData.id
      );
      if (ThisIndex != -1) {
        // Create a shallow copy of the specific rowData item and update the value
        const updatedRowData = {
          ...data[ThisIndex],
          [column.id]: inputValue?.[dataKey]?.[rowData.id],
        };
        // Efficiently create a new array with only the modified item updated
        const updatedIngredients = [
          ...data.slice(0, ThisIndex),
          updatedRowData,
          ...data.slice(ThisIndex + 1),
        ];
        setData((prevData) => {
          if (
            JSON.stringify(prevData) ===
            JSON.stringify(updatedIngredients)
          ) {
            return prevData;
          }
          return updatedIngredients;
        });
        // console.log("checkOnchanges",value)
      }
      setInputValue((prevData) => {
        return {
          ...prevData,
          [dataKey]: {
            [rowData.id]: undefined
          }
        }
      })
      var value = inputValue?.[dataKey]?.[rowData.id];
      setTimeout(() => {
        handleIngredientQty(
          value,
          rowData.id,
          column.id,
          rowData,
          conversion_type,
          dosagesDays
        );
      }, 200)
    }, [inputValue]);

    const handleChange = useCallback((value,event) => {
      if (parseInt(Number(value)).toString().length >= 6) {
        return;
      }
       // Get cursor position before updating state
       const inputElement = event?.target;
       const cursorPosition = inputElement?.selectionStart ?? 0;
       setInputValue(value)
       // Restore cursor position after state updates
      setTimeout(() => {
        inputElement?.setSelectionRange(cursorPosition, cursorPosition);
      }, 0);
    }, [inputValue]);  // ✅ Only re-creates when inputValue changes


    const handleBlur = useCallback(() => {
      if (inputValue === undefined || inputValue == 0) {
        return;
      }     
      
      setInputValue(inputValue)
      
      //setTimeout(() => {
        handleIngredientQty(
          inputValue, // final value which we use to update the state && for calculation
          rowData.id,
          column.id,
          rowData,
          conversion_type,
          dosagesDays
        );
      //}, 200)
    }, [inputValue]);

    return (<>
    {/* {inputValue} */}
    <InputNumber
        //defaultValue={rowData[column.id]}
        disabled={
          rowData.ingredient_id == "" ? true : false
        }
        min={0}
        // value={
        //   inputValue?.[dataKey]?.[rowData.id] === undefined ? decimalLength(rowData[column.id]) >
        //     decimalPlace
        //     ? Number(rowData[column.id]).toFixed(
        //       decimalPlace
        //     )
        //     : rowData[column.id] : inputValue?.[dataKey]?.[rowData.id]
        // }
        value={String(inputValue)}
        onFocus={() => {
          handleFocus(colIndex);
        }}
        onChange={(value, event) => {
          handleChange(value,event)
        }}
        onBlur={(e) => {
          handleBlur()
        }}
        step={rowData?.input_decimal_place}
        renderMenuItem={(label, item) => (
          <div style={fontStyle}>{label}</div>
        )}
        className={
          "IngredientQtyImput " + column.id
        }
      />
    </>
      
    );
  },
  (prevProps, nextProps) => {
    // Prevent re-renders if relevant props haven't changed
    return (
      prevProps.rowData[prevProps.column.id] === nextProps.rowData[nextProps.column.id] &&
      prevProps.rowData.final_decimal_place === nextProps.rowData.final_decimal_place &&
      prevProps.conversion_type === nextProps.conversion_type &&
      prevProps.dosagesDays === nextProps.dosagesDays &&
      prevProps.decimalPlace === nextProps.decimalPlace
    );
  }
);

export default IngredientQtyInput;
