import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
//import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/css/bootstrap.min.css' // Import Bootstrap CSS
import 'rsuite/dist/rsuite.min.css'

// ReactDOM.render(<App />, document.getElementById('root')) // use when no routes
import ErrorBoundary from './ErrorBoundary'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    {/*<ErrorBoundary>*/}
      <App />
    {/*</ErrorBoundary>*/}
  </React.StrictMode>
)
// please don`t change file
reportWebVitals() 
